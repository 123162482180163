import { Avatar, Box, Button, Chip, Grid, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Theme, Tooltip, Typography } from '@mui/material';
import { blue, green, red } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from 'redux';
import { SearchBox } from '../../components/search/SearchBox';
import IFilterMenuOptionsModel from '../../models/common/IFilterMenuOptionsModel';
import IProduct from '../../models/product/IProduct';
import { getCategoryByIdProductRange, resetCategoryByIdProducts } from '../../store/actions/Product.Action';
import { getDateFormatted } from '../../util/getDateFormatted';
import PreviewIcon from '@mui/icons-material/Preview';
import IconButton from "@mui/material/IconButton";

const classNames = require('classnames');

interface IProps {
  classes?: any;
  productsList: any[];
  width: any;
  match: any;
  totalResults: number,
  resetCategoryByIdProducts: () => void;
  getCategoryByIdProductRange: (categoryId: any, start: number, limit: number, parameterName?: string, parameterValue?: string) => void;

}

interface IState {
  pageNo: number,
  rowsPerPage: number,
  pageStartCount: number,
  anchorEl: any;
  selectedFilterMenuIndex: number,
  filterMenuOptions: IFilterMenuOptionsModel[],
  selectedFilterMenu: string | number | boolean;
  selectedFilterMenuKey: string;
  searchBoxValue: string;
  searchBoxFilterKey: string,
  categoryId?: any
}

class ProductGridListView extends React.Component<RouteComponentProps & IProps, IState> {

  constructor(props: any) {
    super(props);
    this.state = {
      pageNo: 0,
      rowsPerPage: 10,
      pageStartCount: 10,
      anchorEl: null,
      selectedFilterMenuIndex: 0,
      filterMenuOptions: [
        { "label": 'Name', "key": 'name' },
      ],
      selectedFilterMenu: 'Name',
      selectedFilterMenuKey: 'name',
      searchBoxValue: '',
      searchBoxFilterKey: '',

    }
  }

  public async componentWillMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.props.getCategoryByIdProductRange(id, 0, 10);
    }
  }

  public componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.setState({
        categoryId: id
      })
    }
  }

  public componentWillUnmount() {
    const { history } = this.props;
    history.replace({ state: {} })
    this.props.resetCategoryByIdProducts();
    this.setState({ categoryId: null })
  }
  // componentDidMount() {
  //     this.props.getCategoryByIdProductRange();
  // }

  public onClickMenu = (product: IProduct) => {
    this.props.history.push(`/product/${product.productId}`);
  }
  private gotoProductDetails = (product: any) => {
    this.props.history.push(`/product/${product.productId}`);
  }


  private handleChangePage = (event: any, page: number) => {
    console.log('')
  };

  private handleChangeTableRowsPerPage = async (event: any) => {
    this.props.getCategoryByIdProductRange(this.state.categoryId, 0, event.target.value as number, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
    this.setState({ rowsPerPage: event.target.value, pageStartCount: event.target.value, pageNo: 0 });
  };
  private loadNextPage = async () => {
    this.props.getCategoryByIdProductRange(this.state.categoryId, this.state.pageStartCount, this.state.rowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
    this.setState({ pageNo: this.state.pageNo + 1, pageStartCount: this.state.pageStartCount + this.state.rowsPerPage });
  }

  private loadPreviousPage = () => {
    this.setState({ pageNo: this.state.pageNo - 1, pageStartCount: this.state.pageStartCount - this.state.rowsPerPage })
    if ((this.state.pageNo - 1) === 0) {
      this.props.resetCategoryByIdProducts();
      this.props.getCategoryByIdProductRange(this.state.categoryId, 0, this.state.rowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
    }
  }

  private onEnterKeyPress = (event: any) => {
    if (event.keyCode === 13 || event.keyCode === '13' || event.key === 'Enter') {
      this.callSearchByParam();
    }
  }
  private callSearchByParam = async () => {
    this.props.getCategoryByIdProductRange(this.state.categoryId, 0, this.state.rowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
  }
  private handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  private handleClose = () => {
    this.setState({ anchorEl: null, });
  }

  private handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: any) => {
    this.setState({ selectedFilterMenuIndex: index, anchorEl: null, selectedFilterMenu: option.label, selectedFilterMenuKey: option.key, searchBoxFilterKey: option.key })
  };

  private handleSearchBox = (event: any) => {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value
    });
  }

  public render(): JSX.Element {
    const { classes, productsList, totalResults } = this.props;
    const { selectedFilterMenuIndex, anchorEl, selectedFilterMenu, filterMenuOptions, pageNo, rowsPerPage, searchBoxValue, } = this.state;

    return (<Paper className={classNames(classes.paper, classes.product, 'container')} elevation={5} square={false}>
      <Grid container={true} style={{ marginTop: 5 }} >
        <Grid item={true} xs={12} sm={10}>
          <SearchBox handleClickListItem={this.handleClickListItem} anchorEl={anchorEl}
            handleClose={this.handleClose} filterMenuOptions={filterMenuOptions} selectedFilterMenuIndex={selectedFilterMenuIndex}
            handleMenuItemClick={this.handleMenuItemClick} selectedFilterMenu={selectedFilterMenu} searchBoxValue={searchBoxValue}
            handleSearchBox={this.handleSearchBox} onEnterKeyPress={this.onEnterKeyPress} gridLabel={'Products'} />
        </Grid>
        <Grid item={true} xs={12} sm={2}>

        </Grid>
      </Grid>
      <hr style={{ color: 'rgb(224 224 224)', border: '1px solid' }} />
      {(Array.isArray(productsList) && productsList.length > 0) &&
        productsList.slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage).map((product: IProduct, index: number) => {
          return (
            <Grid key={product?.productId} className={classes.actionArea} onDoubleClick={() => this.gotoProductDetails(product)} justifyContent={"space-between"} sx={{
              display: 'flex',
              flexDirection: 'row',
              boxShadow: 3,
              m: 2,
              borderRadius: 2,
              textAlign: 'center',
            }}>
              <Grid item xs={0.5} md={1} >

                <Box
                  sx={{ p: 1, m: 1 }}>
                  <Tooltip arrow title="product image">
                    <Avatar sx={{ bgcolor: blue[500], fontSize: 16, ml: 4 }}>
                      {(product?.images && product?.images?.length) ?
                        <img style={{ height: '40px', width: '40px' }} alt="" src={(product.images && product.images.length) ? product.images[0].image : ''} />
                        : <Typography >{product?.name?.charAt(0)}</Typography>
                      }
                    </Avatar>
                  </Tooltip>


                </Box>
              </Grid>
              <Grid item xs={2} md={4}>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Tooltip arrow title="Product Name">
                        <Typography
                          sx={{
                            display: 'inline', fontSize: '1.2rem',
                            fontWeight: '700'
                          }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {product?.name}
                        </Typography>
                      </Tooltip>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        Date Of Submission
                      </Typography>
                      {' — ' + getDateFormatted(product.createDateTime)}
                    </React.Fragment>
                  } />

              </Grid>
              <Grid item xs={0.25} md={1.5}>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        sx={{
                          display: 'inline', fontSize: '1.2rem',
                        }}
                        component="span"
                        variant="subtitle2"
                        color="text.primary"
                      >
                        {product.price}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={"Price"}
                />
              </Grid>
              <Grid item xs={1} md={2}>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Tooltip arrow title="WhiteList Account">
                        <Typography
                          sx={{
                            display: 'inline', fontSize: '1.2rem',
                            fontWeight: '700'
                          }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >{product.stmMcuFamily?.partNo || '_'}
                        </Typography>
                      </Tooltip>

                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        Sensor Manufacturer ID
                      </Typography>

                    </React.Fragment>
                  } />
              </Grid>
              <Grid item xs={1} md={1.5}>

                <Tooltip arrow title="preview">
                  <IconButton sx={{ mr: 2, mt: 1, p: 2 }} onClick={() => this.gotoProductDetails(product)} edge="end" aria-label="delete">
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>

              </Grid>
            </Grid>
          )
        })}
      {(Array.isArray(productsList) && productsList.length > 0) &&
        <TablePagination
          component="div"
          count={totalResults}
          rowsPerPage={rowsPerPage}
          page={pageNo}
          backIconButtonProps={{ 'aria-label': 'Previous Page', 'onClick': this.loadPreviousPage }}
          nextIconButtonProps={{ 'aria-label': 'Next Page', 'onClick': this.loadNextPage, }}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeTableRowsPerPage}
        />}
    </Paper>
    );
  }
}

const styles = (theme: Theme) => ({
  paper: {
    overflow: 'hidden',
    color: theme.palette.text.secondary,
  },
  container: {
    [theme.breakpoints.only('xs')]: {
      marginBottom: '30%',
    },
    [theme.breakpoints.only('sm')]: {
      marginBottom: '12%',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '10%',
    },
  },
  tableContainer: {
    padding: '1px',
    overflow: 'auto'
  },
  iconPointer: {
    cursor: 'pointer'
  },
  dialogContainer: {
    width: '50%',
    height: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      margin: 0,
      maxWidth: '100%',
      maxHeight: 'none',
      borderRadius: 0,
    },
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  tableHeaderBg: {
    backgroundColor: '#f1b665a8',
    fontWeight: 700
  }

});
const mapStateToProps = (state: any) => ({
  productsList: state.product?.productsList,
  totalResults: state.product?.totalResults
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getCategoryByIdProductRange: getCategoryByIdProductRange,
    resetCategoryByIdProducts: resetCategoryByIdProducts
  }, dispatch);
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(ProductGridListView as any)) as React.ComponentType<any>);