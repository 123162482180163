import { ActionType, IAppAction } from '../store/actions/Helpers';
import * as CategoryActions from '../store/actions/Category.Actions';
import axios from 'axios';
import { PORTAL_API_URL, getHeader } from './Base.Service';
import { APIConstantList } from '../AppConstants';
import * as AppActions from '../store/actions/App.Action'
const CategoryMiddleWare = (propsApiMid: any) => (next: any) => async (action: IAppAction) => {
    const { dispatch, getState } = propsApiMid;
    const headers = await getHeader();
    switch (action.type) {
        case ActionType.GET_CATEGORY_LIST_REQUEST:
            next(AppActions.showLoader())
            axios.get(`${PORTAL_API_URL + APIConstantList.getCategoryByRange + action.payload}`, {
                headers: headers,
            }).then((response: any) => {
                next(CategoryActions.fetchCategoryListByRangeSuccess(response.data.data));
                next(AppActions.hideLoader())
            }).catch((error) => {
                next(CategoryActions.fetchCategoryListByRangeFailure());
                next(AppActions.hideLoader())
            });
            break;
        case ActionType.GET_CATEGORY_LIST_DASHBOARD_REQUEST:
            next(AppActions.showLoader())
            axios.get(`${PORTAL_API_URL + APIConstantList.getCategoryByRangeDashboard + action.payload}`, {
                headers: headers,
            }).then((response: any) => {
                next(CategoryActions.fetchCategoryListByProductRangeSuccess(response.data.data));
                next(AppActions.hideLoader())
            }).catch((error) => {
                next(CategoryActions.fetchCategoryListByByProductRangeFailure());
                next(AppActions.hideLoader())
            });
            break;
        case ActionType.GET_LOGIN_USER_DETAILS_REQUEST:
            next(AppActions.showLoader())
            axios.get(`${PORTAL_API_URL}users/user/me`, {
                headers: headers,
            }).then((response: any) => {
                next(AppActions.getLoginUserDetailsByTokenSuccess(response.data?.data))
                next(AppActions.hideLoader())
            }).catch((error) => {
                next(AppActions.getLoginUserDetailsByTokenFail(error))
                next(AppActions.hideLoader())
            });
            break;
        default:
            break;
    }

    await next(action);
};

export default CategoryMiddleWare;
