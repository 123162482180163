import Lock from '@mui/icons-material/LockOutlined';
import { Box, Button, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { ReactComponent as EnglandCrest } from '../../assets/icons/unwired_icon.svg';
import { ReactComponent as PPSLogo } from '../../assets/icons/unwired_logo.svg';
import { withAuth0 } from '@auth0/auth0-react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const styles = {
	app: {
		width: '100%',
		height: '100%',
	},
	crest: {
		position: 'absolute',
		animationName: '$shrink',
		animationDelay: '1s',
		animationDuration: '0.5s',
		animationFillMode: 'forwards',
		bottom: 30,
		transition: 'all .3s',
		left: 55,
		'& path': {
			fill: '#04264C'
		}
	},
	logo: {
		position: 'absolute',
		height: 125,
		opacity: 0,
		animationName: '$fadeLogo',
		animationDelay: '1s',
		animationDuration: '0.5s',
		animationFillMode: 'forwards',
		marginLeft: 30,
		bottom: 30,
		right: 100,
		'& path': {
			fill: '#04264C'
		}
	},
	loginButton: {
		opacity: 0,
		animationName: '$fade',
		animationDelay: '1.5s',
		animationDuration: '0.5s',
		animationFillMode: 'forwards',
	},
	'@keyframes shrink': {
		to: { height: 125, left: 0 }
	},
	'@keyframes fadeLogo': {
		to: { opacity: 1, right: 0 }
	},
	'@keyframes fade': {
		to: { opacity: 1 }
	},
};

class SignIn extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			email: '',
			password: '',
			override: '',
			loader: false
		};
	}
	onChange = (value: any, field: any) => {
		this.setState({ [field]: value.map((option: any) => option.value) });
	}
	loginWithContributorRedirect = () => {
		window.location.href = process.env.REACT_APP_CONTRIBUTOR_APP ? process.env.REACT_APP_CONTRIBUTOR_APP : '';
	}

	render() {
		const { classes, auth0 } = this.props;
		return (
			<Box height={'100vh'} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
				<Grid container={true} justifyContent={'center'} spacing={4} style={{ padding: 40 }}>
					<Grid item={true} xs={12} sm={6} >
						<Card >
							<CardContent style={{ marginTop: 30 }}>
								<Typography align='center' variant='h3' sx={{ fontSize: 18 }} gutterBottom>
									Marketplace Contributor App
								</Typography>
								<Typography component={'div'} align='center' className="d-flex" style={{ position: 'relative', minHeight: 250, width: 547 }}>
									<EnglandCrest className={classes.crest} style={{ height: 150 }} />
									<PPSLogo className={classes.logo} />
								</Typography>
							</CardContent>
							<CardActions style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginBottom: 30 }}>
								<Button
									variant="contained"
									color="primary"
									className={
										classes.loginButton
									}
									onClick={() => this.loginWithContributorRedirect()}
									disabled={auth0?.isLoading}
									size="small">Continue as Contributor</Button>
							</CardActions>
						</Card>
					</Grid>
					<Grid item={true} xs={12} sm={6}  >
						<Card >
							<CardContent style={{ marginTop: 30 }}>
								<Typography align='center' variant='h3' sx={{ fontSize: 18 }} gutterBottom>
									Marketplace User App
								</Typography>
								<Typography component={'div'} align='center' className="d-flex" style={{ position: 'relative', minHeight: 250, width: 547 }}>
									<EnglandCrest className={classes.crest} style={{ height: 150 }} />
									<PPSLogo className={classes.logo} />
								</Typography>
							</CardContent>
							<CardActions style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginBottom: 30 }}>
								<Button
									variant="contained"
									color="primary"
									className={
										classes.loginButton
									}
									onClick={() => auth0?.loginWithRedirect()}
									disabled={auth0?.isLoading}
									size="small">Continue as User</Button>
							</CardActions>
						</Card>
					</Grid>
				</Grid>
			</Box>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
		state
	};
};

export default withAuth0(connect(mapStateToProps, {})(withStyles(styles as any)(SignIn)));
