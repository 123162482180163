import * as React from 'react';
import { Theme, IconButton } from '@mui/material';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';
import { NavLink } from "react-router-dom";
import { createStyles, withStyles, makeStyles, WithStyles } from '@mui/styles'
const styles = (theme: Theme) => createStyles({
    scrollIconAlign: {
        float: 'right',
        padding: '0px',
        marginTop: '11px',
        marginRight: '5px'
    },
    scrollIcon: {
        fontSize: '25px',
        color: 'rgb(0 0 0 / 87%)',
    },
    viewAll: {
        fontSize: '20px',
        float: 'right',
        marginRight: '3%',
        color: '#2c9beb',
        marginTop: '11px',
        "text-decoration": "none",
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        },
    },
});

interface IProps {
    moduleType: string;
    viewAllPath?: string;
    isEnableScroll?: boolean,
}

class ScrollButtons extends React.Component<WithStyles<typeof styles> & IProps> {
    private clickLeft = (moduleType: string) => {
        const module = document.getElementById(moduleType);
        if (module) {
            module.scrollLeft += module.offsetWidth * 0.8;
        }

    }

    private clickRight = (moduleType: string) => {
        const module = document.getElementById(moduleType);
        if (module) {
            module.scrollLeft -= module.offsetWidth * 0.8;
        }
    }

    public render() {
        const { classes, moduleType, viewAllPath, isEnableScroll } = this.props;

        return (<>
            {isEnableScroll && <>
                <IconButton type="button" className={classes.scrollIconAlign} onClick={() => this.clickLeft(moduleType)} aria-label="scroll-left">
                    <ArrowForwardIos className={classes.scrollIcon} />
                </IconButton>
                <IconButton type="button" className={classes.scrollIconAlign} onClick={() => this.clickRight(moduleType)} aria-label="scroll-right">
                    <ArrowBackIos className={classes.scrollIcon} />
                </IconButton></>}

            {!!viewAllPath &&
                <span>
                    <NavLink className={classes.viewAll} activeClassName="active" to={viewAllPath}>View All</NavLink>
                </span>
            }
        </>);
    }
}

export default withStyles(styles as any)(ScrollButtons);