import React from 'react';
import './ProductSlider.css';
import { makeStyles, } from '@mui/styles';
import { Theme } from '@mui/material';
const useStyles = makeStyles((theme: Theme) => ({
    '.gallery-wrap .img-small-wrap .item-gallery': {
        width: '40px',
        height: '60px',
        border: '1px solid #ddd',
        margin: '7px 2px',
        display: 'inline-block',
        overflow: 'hidden'
    },

    '.gallery-wrap .img-small-wrap': {
        'text-align': 'center'
    },
    '.gallery-wrap .img-small-wrap img': {
        'max-width': '100%',
        'max-height': '100%',
        'object-fit': 'cover',
        'border-radius': '4px',
        cursor: 'pointer',
    },
    image: {
        width: '100%',
        height: 'auto',
    },
    imgNavContainer: {
        position: 'relative',
        width: 'fit-content',
        margin: 'auto',
    },
    imgContainer: {
        padding: '0px !important',
        width: '100%',
        height: '100%',
    },
}));
const ProductSlider = (props: any) => {
    const classes = useStyles();
    const images = props.images;
    const imageRef = React.createRef<HTMLImageElement>();
    const [img, setImg] = React.useState(Array.isArray(images) ? images[0]?.image : images);
    // const [aItem, setAItem] = React.useState(0);
    // const handleImageChange = (e: any) => {
    //     const currentX = e.clientX - cumulativeOffSet(imageRef.current).left;
    //     if (images && Array.isArray(images)) {
    //         const part = imageRef.current.clientWidth / images.length;
    //         let imgIndex = Math.ceil(currentX / part) - 1;
    //         if (imgIndex < 0) {
    //             imgIndex = 0;
    //         }
    //         if (imgIndex >= images.length) {
    //             imgIndex = images.length - 1;
    //         }
    //         // setAItem(imgIndex);
    //         setImg(images[imgIndex]);
    //     }
    // };
    // const handleMouseOut = (e: any) => {
    //     setImg(images[0]);
    //     // setAItem(0);
    // };

    const changeImage = (i: number) => {
        setImg(images[i].image);
        // setAItem(i);
    }
    return (
        <aside className="col-sm-5 border-right">
            <article className="gallery-wrap">
                {/* <div className="img-big-wrap">
                    <div className={classes.imgNavContainer}>
                        <div className={classes.imgContainer} style={{ padding: '1rem' }}><img
                            ref={imageRef}
                            alt=""
                            className={classes.image}
                            // onMouseMove={handleImageChange}
                            // onMouseOut={handleMouseOut}
                            src={img}
                        /></div>
                    </div>
                </div> */}
                <div className="img-small-wrap">
                    {Array.isArray(images) ? images.length > 0 ? images.map((showImg: any, i: number) => (
                        <div className="item-gallery" key={i} onClick={() => { changeImage(i) }}><img key={i} alt="" src={`${showImg.image}`} /></div>
                    )) : '' : ''}
                </div>
            </article>
        </aside>
    );
};

export default ProductSlider;