
import { Grid, ImageList, Theme, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators, Dispatch } from 'redux';
import CategoryCard from '../../components/Cards/CategoryCard';
import ScrollButtons from '../../components/scroll/ScrollButtons';
import ICategory from '../../models/category/ICategory';
import IProduct from '../../models/product/IProduct';
import { User } from '../../state/User';
import { Utility } from '../../state/Utility';
import * as CategoryAction from '../../store/actions/Category.Actions';

const classNames = require('classnames');

interface IDashboardProps {
    classes?: any;
    utility?: Utility;
    shoppingCart?: any;
    productsList: any[];
    user: User;
    featuredCategory1row: ICategory;
    featuredCategory2row: ICategory;
    featuredCategoryName1row: string;
    featuredCategoryName2row: string;
    categoryList: any[];
    getCategoryListByRange: (start: number, limit: number) => void;
    getCategoryByRangeDashboard: (start: number, limit: number) => void;
}

interface IPageState {
    searchValue: string;
}
const bgColors = [
    { "id": 0, "bgColor": "#e6194B" },
    { "id": 1, "bgColor": "#f58231" },
    { "id": 3, "bgColor": "#bfef45" },
    { "id": 4, "bgColor": "#3cb44b" },
    { "id": 5, "bgColor": "#42d4f4" },
    { "id": 6, "bgColor": "#4363d8" },
    { "id": 7, "bgColor": "#911eb4" },
    { "id": 8, "bgColor": "#a9a9a9" },
    { "id": 9, "bgColor": "#000075" },
    { "id": 2, "bgColor": "#ffe119" },
];


class ProductDashboard extends React.Component<RouteComponentProps<{}> & IDashboardProps, IPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            searchValue: '',
        }
    }
    public componentDidMount() {
        window.scrollTo(0, 0)
        this.props.getCategoryListByRange(0, 40);
        this.props.getCategoryByRangeDashboard(0, 40)
    }
    private handleProduct = async (product: IProduct) => {
        this.props.history.push(`/product/${product.productId}`);
    }
    public onClickHandleMenu = (data: any) => {
        this.props.history.push(`/category/menu/${data.categoryId}`);
    }
    public render(): JSX.Element {
        let counter: number = 0;
        const { classes, categoryList } = this.props;
        return (
            <Typography component="div" className={classes.container}>
                {categoryList && <Typography component="div" className={classes.alignNav}>
                    <Typography component="div" className={classes.title} >
                        <Typography component="div" className={classes.sectionText}>
                            {'All Category'}
                            {categoryList !== undefined && <ScrollButtons isEnableScroll={false} moduleType="category-container-one" viewAllPath="/category-list-view" />}
                        </Typography>
                        <Typography component="div" style={{ paddingLeft: 30 }}>
                            <Grid className={classes.categoryGrid} container={true} spacing={4}>
                                {!!categoryList
                                    ? categoryList?.map((menuCategory: ICategory, index: number) => (
                                        <Grid item={true} sm={4} xs={12} xl={4} style={{ padding: 0, margin: 0 }} key={menuCategory.categoryId + 'unique' + index}>
                                            <CategoryCard category={menuCategory}
                                                bgColor={bgColors[counter++ % 10].bgColor}
                                                onClick={() => { this.onClickHandleMenu(menuCategory) }} />
                                        </Grid>
                                    )) : <ImageList>
                                        <Typography align='center' component="span"
                                            variant="body1"
                                            color="textPrimary">No Category Found</Typography>
                                    </ImageList>}
                            </Grid>
                        </Typography>
                    </Typography>
                </Typography>}
            </Typography>)
    }
}

const styles = (theme: Theme) => ({
    flexContainer: {
        scrollBehavior: 'smooth',
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        overflowY: 'hidden',
        [theme.breakpoints.between('sm', 'xl')]: {
            padding: 35,
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: 20,
            paddingBottom: 20,
        },
    },
    loader: {
        position: 'sticky',
        'text-align': 'center',
        margin: '0 auto',
        top: '50%',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    title: {
        // padding: '5px',
        width: '100%',
        textAlign: 'left',
    },
    sectionText: {
        fontSize: '34px !important',
        [theme.breakpoints.down('xs')]: {
            fontSize: '27px !important',
        },
    },


    section: {
        fontSize: '34px !important',
        backgroundColor: 'white',
        padding: '5px',
        width: '100%',
        textAlign: 'left',
    },
    container: {
        backgroundColor: "#f3f3f6",
        minWidth: '95%',
        [theme.breakpoints.between('xs', 'sm')]: {
            marginBottom: '25%',
        },
        [theme.breakpoints.up('sm')]: {
            marginBottom: '4%',
        },

    },
    alignNav: {
        fontSize: 18,
        fontWeight: 50,
        color: '#000000',
        textDecoration: 'none'
    },
    root: {
        // 'flex-shrink': 'unset',
        // alignItems: 'center',
    },



    gridDealTileStyle: {
        cursor: 'pointer',
        [theme.breakpoints.only('xs')]: {
            minWidth: '300px',
            margin: 0,
        },
        [theme.breakpoints.between('sm', 'xl')]: {
            minWidth: '400px',
            maxWidth: '350px'
        },
    },
    categoryGrid: {
        [theme.breakpoints.only('xs')]: {
            marginBottom: '32%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
        overflow: 'hidden',
    },
});

const mapStateToProps = (state: any) => ({
    categoryList: state.category.categoryList,
    featuredCategory1row: state.category.featuredCategory1row,
    featuredCategory2row: state.category.featuredCategory2row,
    featuredCategoryName1row: state.category.featuredCategoryName1row,
    featuredCategoryName2row: state.category.featuredCategoryName2row,
    productsList: state.product.productsList
})
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getCategoryByRangeDashboard: CategoryAction.getCategoryByRangeDashboard,
        getCategoryListByRange: CategoryAction.fetchCategoryListByRange
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(ProductDashboard as any)) as React.ComponentType<any>);