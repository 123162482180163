import { IAppAction, ActionType } from './Helpers';
import { Spinner } from '../../state/Spinner';
import { AppState } from "../../state/AppState";
import { Dispatch } from "redux";
import { toast } from 'react-toastify';
import * as ApiVariables from '../../service/ApiVariables'

export const getCartDetails = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {

};


export const putUpdateCartItem = (cartId: string, body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {

}

export const deleteCartItem = (cartItemId: string) => {

}



export const clearCartProductDetails = (): IAppAction => {
    return { type: ActionType.REMOVE_CART_PRODUCT_DETAILS, payload: {} };
};
export const editCartProductDetails = (cartItem: any): IAppAction => {
    return { type: ActionType.EDIT_CART_PRODUCT_DETAILS, payload: cartItem };
};

export const postCreateCart = (body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        api({ ...ApiVariables.cartApi.postCreateCart, body }).then((response: any) => {
            toast(response.status.message, { type: 'success' })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            toast("Something went wrong", { type: 'error' })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(response);
        })
    })
}

export const putUpdateCart = (cartId: string, body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.cartApi.putUpdateCartById.cartId = cartId;
        api({ ...ApiVariables.cartApi.putUpdateCartById, body }).then((response: any) => {
            toast(response.status.message, { type: 'success' })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            toast("Something went wrong", { type: 'error' })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(response);
        })
    })
}

export const getShoppingCartDetails = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        api(ApiVariables.cartApi.getCartDetails).then(async (response: any) => {
            if (response.status.success === "SUCCESS") {
                dispatch(fetchCartDetailsSuccess(response))
            } else {
                await getShoppingCartDetailsUpdate(dispatch, api);
            }
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((error: any) => {
            toast("Something went wrong", { type: 'error' });
            dispatch(fetchCartDetailsFailure(error))
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(error);
        })
    })
}

export const deleteCartItemById = (cartItemId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.cartItemApi.deleteCartItemById.itemId = cartItemId;
        api(ApiVariables.cartItemApi.deleteCartItemById).then(async (response: any) => {
            toast(response.status.message, { type: 'success' })
            await getShoppingCartDetailsUpdate(dispatch, api);
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((error: any) => {
            toast("Something went wrong", { type: 'error' });
            dispatch(fetchCartDetailsFailure(error))
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(error);
        })
    })
}

export const getShoppingCartDetailsUpdate = (dispatch: any, api: any) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        api(ApiVariables.cartApi.getCartDetails).then((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            if (response.status.success === "SUCCESS") {
                dispatch(fetchCartDetailsSuccess(response))
            }
            resolve(response);
        }).catch((error: any) => {
            toast("Something went wrong", { type: 'error' });
            dispatch(fetchCartDetailsFailure(error))
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(error);
        })
    })
}




export function fetchCartDetailsSuccess(response: any) {
    return {
        type: ActionType.GET_CART_DETAILS_SUCCESS,
        payload: response.data,
    };
}

export function fetchCartDetailsFailure(error: any) {
    return {
        type: ActionType.GET_CART_DETAILS_FAILURE,
        payload: error,
    };
}


