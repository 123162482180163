import { ActionType, IAppAction } from '../../actions/Helpers';

const initialState = {
  user: null,
  authInfo: undefined,
};
export const auth = (state = initialState, action = {} as IAppAction) => {
  switch (action.type) {
    case ActionType.GET_LOGIN_USER_DETAILS_SUCCESS: {
      const userData = action.payload;
      localStorage.setItem('userId', userData?.userId)
      if (!state.user) {
        return {
          ...state,
          authInfo: [userData?.userType],
          user: userData
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case ActionType.GET_LOGIN_USER_DETAILS_FAILURE: {
      return {
        ...state,
        user: null,
        authInfo: undefined,
      };
    }
    default:
      return state;
  }
};
