import React from 'react';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { formatMoney } from "../../pipes/PriceFormatter";
import { Grid, Button, Theme,CardMedia,Accordion ,AccordionDetails,AccordionSummary,Tooltip,Box,CardContent,Card,IconButton} from '@mui/material';
import { getOrderHistory } from '../../store/actions/Order.Action';
import OrderHistoryCard from '../../components/Order/OrderHistoryCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const classNames = require('classnames');


interface IPageProps {
    classes?: any;
    history?: any;
    match: any;
    orderHistory?: any;
    getOrderHistory: () => void;
}

interface IPageState {
    title?: string;
    expanded?:any

}

class OrderHistory extends React.Component<IPageProps, IPageState> {
    

     handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        this.setState({ expanded: isExpanded ? panel : false })
      };

    constructor(props: any) {
        super(props)
        this.state = {}
    }

    public UNSAFE_componentWillMount() {
        this.props.getOrderHistory();
    }

    private goShoppingPage = () => {
        this.props.history.push('/products');
    };
    public validateUrl = (value: string) => {
        const isValid = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
        if (isValid) {
            return value;
        } else {
            return 'data:image/png;base64,' + value;
        }

    }
    public productGitLink = (product:any) => {
        this.props.history.push('/instruction',{'product':product});
    }
    public render() {
        const { classes, orderHistory } = this.props;
        if (!orderHistory && orderHistory?.length === 0) {
            return (<div>
                <Typography gutterBottom={true} className={classes.headingPrimary}>No orders found<br /></Typography>
                <Button color='primary' style={{
                    color: '#141515',
                    backgroundColor: '#bbdefb',
                    marginBottom: 10,
                }} onClick={this.goShoppingPage}>BACK TO SHOPPING</Button>
            </div>
            )
        }
        return (<div className={classes.root}>
            <Typography gutterBottom={true} align="center" variant="h6" component="p">Your Order History</Typography>
           

            {orderHistory.map((orderItem: any, indexNumber: any) => {
                return (
                    <Accordion key={indexNumber} expanded={this.state.expanded === indexNumber} onChange={this.handleChange(indexNumber)}>
                        <AccordionSummary
                            expandIcon={orderItem.items.length>0?<ExpandMoreIcon />:' '}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography component="div" variant="h5" >
                                <Tooltip title={orderItem.status}>
                                    <IconButton>
                                        {orderItem.status == 'Order Success' && <CheckCircleOutlineIcon style={{ color: "green" }} />}
                                        {orderItem.status == 'Order failed' && <HighlightOffIcon style={{ color: "red" }} />}
                                        {orderItem.status == 'Order hold' && <ErrorOutlineIcon style={{ color: "yellow" }} />}
                                    </IconButton>
                                </Tooltip>

                            </Typography>
                            <Typography sx={{ width: '60%', paddingTop: '10px', flexShrink: 0 }}>
                                <b> Order Id: # {orderItem.orderId} </b>
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ width: '10%', paddingTop: '10px' }}>
                                Tax: <b>$ {orderItem.tax}</b>
                            </Typography>

                            <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ width: '15%', paddingTop: '10px', flexShrink: 0 }}>
                                Sub Total: <b>$ {orderItem.subTotal}</b>
                            </Typography>
                            <Typography sx={{ width: '15%', paddingTop: '10px', flexShrink: 0 }}> Total: <b> $ {orderItem.total}</b></Typography>
                          
                        
                        </AccordionSummary>
                        <AccordionDetails>
                        
                            {orderItem.items.map((item: any, indexNumber: any) => {
                                return (
                                    <Card key={indexNumber} sx={{ display: 'flex', marginBottom: 2, }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' , width: '100%'}}>
                                        <CardMedia component="img"sx={{ width: 151 }} src={`${(item.product.images ) ? this.validateUrl(item.product.images[0]?.image) : ''}`} />

                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                             
                                                <Typography component="div" sx={{ display: 'flex', flexDirection: 'row'}}   >
                                                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{width: '50%' }}>
                                                      <b> {item.product.name}</b><br />
                                                        <p>
                                                            {item.product.description.length > 100 ?
                                                                `${item?.product?.description.substring(0, 100)}...` : item.product.description
                                                            }
                                                        </p>
                                                    </Typography>
                                                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{width: '20%' }}>
                                                      <b> Category</b>
                                                      <Typography  component="p" >
                                                            {item?.product?.name}
                                                      </Typography>
                                                    </Typography>
                                                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{width: '20%' }}>
                                                      <b> Price</b>
                                                      <Typography  component="p" >
                                                            {item?.product?.price}
                                                      </Typography>
                                                    </Typography>
                                                    <Typography  color="text.secondary" component="div" sx={{width: '20%' }}>
                                                    <Button variant="contained" disabled={item?.product?.productGitLink==null}   onClick={() => this.productGitLink(item.product)}  color='primary'  >
                                                        product Git Link
                                                    </Button>
                                                    </Typography>

                                                </Typography>
                                            </CardContent>
                                        </Box>
                                    </Card>

                                )
                            })} 

                        </AccordionDetails>
                    </Accordion>
                )
            })}



            
            {/* <Container component="main" disableGutters={false} maxWidth="lg" className={classNames(classes.mainContainer)} style={{ overflow: 'scroll', overflowY: 'scroll' }}>
                {Array.isArray(orderHistory) && orderHistory?.length > 0 ?
                    <Grid container={true} style={{ backgroundColor: 'transparent',display:'flex',justifyContent:'center' }}>
                        <Grid item={true} xs={12} sm={6} className={classes.productListReviewContainer} >
                            {orderHistory.map((orderItem:any,indexNumber:number)=>{
                                return(<OrderHistoryCard orderItem={orderItem}/>)
                            })}
                        </Grid>
                        <Grid item={true} xs={12} sm={12} style={{ textAlign: 'center', marginTop: 10 }}>
                            <Button color='primary' style={{
                                color: '#141515',
                                backgroundColor: '#bbdefb',
                                marginBottom: 10,
                            }} onClick={this.goShoppingPage}>BACK TO SHOPPING</Button>
                        </Grid>
                    </Grid>
                    : <Grid>
                        <Grid item={true} xs={12} sm={12} style={{ textAlign: 'center', marginTop: 10 }}>
                            <Button color='primary' style={{
                                color: '#141515',
                                backgroundColor: '#bbdefb',
                                marginBottom: 10,
                            }} onClick={this.goShoppingPage}>BACK TO SHOPPING</Button>
                        </Grid>

                    </Grid>}
            </Container> */}
        </div>);
    }
}

const styles = (theme: Theme) => ({
    root: {
        marginTop: '10px',
        marginBottom: '70px',
    },
    productListReviewContainer: {
        marginBottom: '15px',
       // backgroundColor: 'white',

    },
    productListReview: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1),
    },
    marginBottom: {
        marginBottom: '5px',
    },
});
const mapStateToProps = (state: any) => ({
    orderHistory: state.orderStore?.orderHistoryList
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({
        getOrderHistory: getOrderHistory
    }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(OrderHistory)) as React.ComponentType<any>);