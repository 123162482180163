import { IAppAction, ActionType } from './Helpers';
import { Spinner } from '../../state/Spinner';
import { AppState } from "../../state/AppState";
import { Dispatch } from "redux";
import { toast } from 'react-toastify';
import * as ApiVariables from '../../service/ApiVariables'

export function showLoader() {
  return {
    type: ActionType.OPEN_SPINNER
  };
}

export function hideLoader() {
  return {
    type: ActionType.CLOSE_SPINNER
  };
}

export const cartPopWindowOpen = () => {
  return {
    type: ActionType.ADD_CART_POP_WINDOW_OPEN,
  }
};

export const cartPopWindowClose = () => {
  return {
    type: ActionType.ADD_CART_POP_WINDOW_CLOSE,
  }
};


export const getLoginUserDetailsByTokenSuccess = (response: any) => {
  return {
    type: ActionType.GET_LOGIN_USER_DETAILS_SUCCESS,
    payload: response
  }
};
export const getLoginUserDetailsByTokenFail = (error: any) => {
  return {
    type: ActionType.GET_LOGIN_USER_DETAILS_FAILURE,
    payload: error
  }
};

export const openProductDetailPageModel = () => {
  return {
    type: ActionType.OPEN_PRODUCT_DETAIL_PAGE_MODEL,
  }
};
export const closeProductDetailPageModel = () => {
  return {
    type: ActionType.CLOSE_PRODUCT_DETAIL_PAGE_MODEL,
  }
};


export function logoutStateClear() {
  return {
    type: ActionType.GET_LOGIN_USER_DETAILS_FAILURE
  };  
}

export const getLoginUserDetailsByToken = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
  return new Promise((resolve, reject) => {
      api(ApiVariables.login.getUserDetails).then((response: any) => {
          dispatch({ type: ActionType.CLOSE_SPINNER });
          dispatch(getLoginUserDetailsByTokenSuccess(response.data))
          resolve(response);
      }).catch((error: any) => {
          dispatch(getLoginUserDetailsByTokenFail(error))
          dispatch({ type: ActionType.CLOSE_SPINNER });
          reject(error);
      })
  })
}