import { RouteAccess } from '../AppConstants';
import LoginButton from '../components/Auth/LoginButton';
import CategoryHome from '../pages/category/CategoryHome';
import CategoryMenuView from '../pages/category/CategoryMenuView';
import ProceedToCheckout from '../pages/checkout/ProceedToCheckout';
import ProductDashboard from '../pages/dashboard/ProductDashboard';
import instruction from '../pages/instruction/instruction';
import OrderHistory from '../pages/orders/OrderHistory';
import OrderSuccess from '../pages/orders/OrderSuccess';
import ProductGridListView from '../pages/product-list-view/ProductGridListView';
import ProductListView from '../pages/product-list-view/ProductListView';
import ProductDetailView from '../pages/product/ProductDetailView';
import Profile from '../pages/profile/Profile';
const routes = [
  {
    path: '/login',
    component: LoginButton,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/',
    component: LoginButton,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/dashboard',
    component: ProductDashboard,
    exact: true,
    access: RouteAccess.User,
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/product/:id',
    component: ProductDetailView,
    exact: true,
    access: RouteAccess.User,
  },
  {
    path: '/product/edit-cart-item:id',
    component: ProductDetailView,
    exact: true,
    access: RouteAccess.User,
  },
  {
    path: '/checkout',
    component: ProceedToCheckout,
    exact: true,
    access: RouteAccess.User,
  },
  {
    path: '/category-list-view',
    component: CategoryHome,
    exact: true,
    access: RouteAccess.User,
  },
  {
    path: '/product-list-view',
    component: ProductListView,
    exact: true,
    access: RouteAccess.User,
  },
  {
    path: '/category/menu/:id',
    component: ProductGridListView,
    exact: true,
    access: RouteAccess.User,
  },
  {
    path: '/order-success/:id',
    component: OrderSuccess,
    exact: true,
    access: RouteAccess.User,
  },
  {
    path: '/order-history',
    component: OrderHistory,
    exact: true,
    access: RouteAccess.User,
  },
  {
    path: '/instruction',
    component: instruction,
    exact: true,
    access: RouteAccess.User,
  },
  {
    path: '*',
    component: LoginButton,
    exact: true,
    access: RouteAccess.All,
  },
];

export default routes;
