import React from 'react';
import { Typography, Grid, Button } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { Theme } from '@mui/material/styles';
import { GetFormattedNiceDate } from '../../util/GetFormattedNiceDate';
import { withStyles } from '@mui/styles';
import * as OrderActions from '../../store/actions/Order.Action';


interface IOrderSuccessProps {
  classes?: any;
  dispatch?: Dispatch;
  history?: any;
  match: any;
  order: any;
  getOrderDetailsById: (orderId: number) => any;
}

interface IOrderSuccessState {
  title: string;
  order: any;
}

class OrderSuccess extends React.Component<IOrderSuccessProps, IOrderSuccessState> {

  constructor(props: any) {
    super(props)
    this.state = {
      title: 'Order',
      order: {
        id: 123,
        type: "Delivery",
        estimatedReadyTime: 4564665456464,
        orderDelivery: {
          address1: "",
          address2: '',
          city: '',
          postalCode: 25455,
          stateProvince: 'NY',
        },
        customerPhone: '21345645454',
        location: {
          name: 'NY city',
          address1: "",
          address2: '',
          city: '',
          postalCode: 25455,
          stateProvince: 'NY',
        }

      },
    }
  }

  private goShoppingPage = () => {
    this.props.history.push('/');
  };

  public componentDidMount() {
    const { getOrderDetailsById } = this.props;
    const orderId = this.props.match.params.id;
    if (orderId && orderId !== 'undefined' && orderId !== 'null') {
      getOrderDetailsById(orderId).then((order: any) => {
        order.location = {};
        this.setState({ order });
      });
    } else {
      this.props.history.push('/products')
    }
  }

  public render() {
    const { order } = this.props;

    if (!order || !order.orderId) {
      return <></>
    }
    const { address1,
      address2,
      city,
      country,
      email,
      firstName,
      grandTotal,
      itemDiscount,
      lastName,
      middleName,
      mobileNo,
      orderId,
      province,
      shipping,
      status,
      subTotal,
      tax,
      total,
      zipCode } = this.props.order;
    const { classes } = this.props;
    const isDelivery = order?.type === 'Delivery';

    return (
      <div style={{ marginBottom: '30px' }}>
        <div className={classes.container}>
          <Typography gutterBottom={true} className={classes.headingPrimary}>Thank you for ordering from <br />unwired</Typography>
          <Typography variant="subtitle1" align='center'> Your order number is <span style={{ fontWeight: 700 }}>#{orderId}</span>.<br />
            We have emailed your order confirmation, and will send you an update when your order is ready.</Typography>
          <Typography variant="subtitle1" align='center'>If you need to change or cancel your order, please call the restaurant at <span style={{ fontWeight: 700 }}>#{'21345645454'}</span>.</Typography>
        </div>

        <div className={classes.form}>
          <div className={classes.checkoutDetails}>
            <Grid container={true}>
              <Grid item={true} xs={12} sm={12}>
                <Grid container={true}>
                  <Grid item={true} xs={12} sm={10} >
                    <Typography className={classes.itemsHeader}>
                      {'Delivery Info'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={10} >
                  <Typography className={classes.pickupEstTime}>{'Estimated Delivery Time:'} <br /> {GetFormattedNiceDate(new Date(Date.now() + 4 * 24 * 60 * 60 * 1000))} </Typography>
                </Grid>
              </Grid>
              <Grid container={true} className={classes.pickupOrderAddress}>
                <Grid item={true} xs={12} sm={12}>
                  <div className={classes.locationPickupDetails}>
                    <div className={classes.mapContainer}>
                      <div className={classes.mapArea}>
                        <img className={classes.mapimage} alt="" src='\static\images\social\map-placeholder.png' />
                      </div>
                    </div>
                    {isDelivery ?
                      <div className={classes.locationContainer}>
                        <div className={classes.locationName}>
                          {firstName}
                        </div>
                        <div className={classes.locationStreet}>
                          {`${address1} ${address2}`}
                        </div>
                        <div className={classes.locationCityStProvContainer}>
                          <div className={classes.locationCityStProv}>
                            {city}
                          </div>
                          <div className={classes.locationCityStProv}>
                            {province}
                          </div>
                          <div className={classes.locationCityStProv}>
                            {zipCode}
                          </div>
                        </div>
                        <div className={classes.locationPhone}>
                          {mobileNo}
                        </div>
                      </div>
                      :
                      <div className={classes.locationContainer}>
                        <div className={classes.locationName}>
                          {city}
                        </div>
                        <div className={classes.locationStreet}>
                          {`${address1} ${address2}`}
                        </div>
                        <div className={classes.locationCityStProvContainer}>
                          <div className={classes.locationCityStProv}>
                            {city}
                          </div>
                          <div className={classes.locationCityStProv}>
                            {province}
                          </div>
                          <div className={classes.locationCityStProv}>
                            {zipCode}
                          </div>
                        </div>
                        <div className={classes.locationPhone}>
                          {mobileNo}
                        </div>
                        <div className={classes.locationDirections}>
                          Get Directions
                        </div>
                        <div className={classes.pickupCarDetails}>
                          Curbside Pickup is available.
                        </div>
                      </div>
                    }
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div className={classes.checkoutSummary}>
            <Grid container={true}>
              <Grid item={true} xs={12} sm={12}>
                <Grid container={true}>
                  <Grid item={true} xs={8} sm={8}>
                    <Typography className={classes.itemsHeader}>Item</Typography>
                  </Grid>
                  <Grid item={true} xs={2} sm={2}>
                    <Typography className={classes.itemsHeader}>Qty</Typography>
                  </Grid>
                  <Grid item={true} xs={2} sm={2} >
                    <Typography className={classes.itemsHeader}>Price</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {order.orderItems && order.orderItems.map((item: any) => {
                return (
                  <Grid key={`${item.id}_order_item`} item={true} xs={12} sm={12}>
                    <Grid container={true} className={classes.itemsDetail}>
                      <Grid item={true} xs={8} sm={8}>
                        <Typography>{item.name}</Typography>
                      </Grid>
                      <Grid item={true} xs={2} sm={2} >
                        <Typography>{item.quantity}</Typography>
                      </Grid>
                      <Grid item={true} xs={2} sm={2}>
                        <Typography>$ {item.price}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
              <Grid item={true} xs={12} sm={12}>
                <Grid container={true}>
                  <Grid item={true} xs={12} sm={12}>
                    <Typography className={classes.sectionHeader}>Totals</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <Grid container={true} className={classes.totalDetail}>
                  <Grid item={true} xs={10} sm={10}>
                    <Typography>Subtotal</Typography>
                  </Grid>
                  <Grid item={true} xs={2} sm={2}>
                    <Typography>$ {parseFloat(subTotal).toFixed(2)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <Grid container={true} className={classes.totalDetail}>
                  <Grid item={true} xs={10} sm={10}>
                    <Typography>Service charge</Typography>
                  </Grid>
                  <Grid item={true} xs={2} sm={2}>
                    <Typography>$ {0}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <Grid container={true} className={classes.totalDetail}>
                  <Grid item={true} xs={10} sm={10}>
                    <Typography>Tax</Typography>
                  </Grid>
                  <Grid item={true} xs={2} sm={2}>
                    <Typography>$ {tax}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <Grid container={true} className={classes.totalDetail}>
                  <Grid item={true} xs={10} sm={10}>
                    <Typography>Tip</Typography>
                  </Grid>
                  <Grid item={true} xs={2} sm={2}>
                    <Typography>$ {0}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <Grid container={true} className={classes.totalDetail}>
                  <Grid item={true} xs={10} sm={10}>
                    <Typography className={classes.orderTotal}>Order Total</Typography>
                  </Grid>
                  <Grid item={true} xs={2} sm={2}>
                    <Typography className={classes.orderTotal}>$ {subTotal}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <Typography component={'div'} style={{ alignItems: 'center', display:'flex',justifyContent:'center',}}>
          <Button variant="contained"
            color="secondary"
            style={{ margin: '10px', borderRadius: '50px' }}
             onClick={() => this.props.history.push('/products')}>BACK TO SHOPPING</Button>
        </Typography>
      </div>

    );
  }
}

const styles = (theme: Theme) => ({
  container: {
    margin: '5% auto',
    [theme.breakpoints.down('xs')]: {
      margin: '5%',
    },
  },
  listItem: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  headingPrimary: {
    textAlign: "center",
    justifyContent: "center",
    fontSize: '25px',
    fontWeight: 900,
    textTransform: 'uppercase',
    letterSpacing: '3px',
    lineHeight: '40px',
  },
  form: {
    display: 'flex',
    margin: '5%',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  checkoutDetails: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  checkoutSummary: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  pickupEstTime: {
    letterSpacing: '1px',
    textAlign: 'center',
    marginBottom: '15px',
  },
  pickupOrderAddress: {
    marginBottom: '36px',
  },
  locationPickupDetails: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  locationContainer: {
    display: 'inline-block',
    paddingLeft: '18px',
    verticalAlign: 'top',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      paddingLeft: '0px',
      paddingTop: '15px',
    },
  },
  locationName: {
    fontSize: '16px',
    lineHeight: '27px',
    fontWeight: 600,
  },
  locationStreet: {
    color: '#4a4a4a',
    lineHeight: '27px',
    fontWeight: 400,
  },
  locationCityStProvContainer: {
    display: 'flex',
  },
  locationCityStProv: {
    color: '#4a4a4a',
    lineHeight: '27px',
    fontWeight: 400,
    marginRight: '5px',
  },
  locationPhone: {
    color: '#be9453',
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: '27px',
    cursor: 'pointer',
  },
  locationDirections: {
    color: '#be9453',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.5px',
    lineHeight: '27px',
    margin: '10px 0px 10px 0px',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  pickupCarDetails: {
    fontSize: '12px',
    color: '#663300',
    letterSpacing: '0.5px',
    lineHeight: '27px',
    marginTop: '10px',
  },
  mapContainer: {
    margin: '0 16px',
  },
  mapArea: {
    width: '160px',
    height: '160px',
    border: '1px solid #9f9d9a',
    margin: 'auto',
  },
  map: {
    maxWidth: '100%',
    width: '100%',
    // background: "url('/static/images/map-placeholder.png') no-repeat center center",
  },
  sectionHeader: {
    color: '#9f9d9a',
    fontSize: '11px',
    fontWeight: 600,
    letterSpacing: '1.5px',
    lineHeight: '16.5px',
    textAlign: 'center',
    textTransform: 'uppercase',
    borderBottom: '1px solid #EAEAEA',
    marginBottom: '5px',
  },
  itemsHeader: {
    color: '#9f9d9a',
    fontSize: '11px',
    fontWeight: 600,
    letterSpacing: '1.5px',
    lineHeight: '16.5px',
    textTransform: 'uppercase',
    borderBottom: '1px solid #EAEAEA',
    marginBottom: '5px',
  },
  itemsDetail: {
  },
  totalDetail: {
    margin: '5px 0px 5px 0px',
  },
  orderTotal: {
  },
  footer: {
    backgroundColor: '#252525',
    color: '#be9453',
    height: '120px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      height: '245px',
    },
  },
  footerContent: {
    display: 'flex',
    height: '50px',
    justifyContent: 'center',
    paddingTop: '20px',
    textAlign: 'center',
    color: 'white',
    fontSize: '18px',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      minHeight: '180px',
      fontSize: '16px',
    },
  },
  footerSection: {
    flex: 1,
  },
  footerBottom: {
    backgroundColor: 'black',
    color: '#be9453',
    height: '60px',
    width: '100%',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    paddingTop: '15px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
      paddingTop: '10px',
    },
  },
  social: {
    display: 'flex',
    marginTop: '-10px',
    paddingRight: '5px',
    textAlign: 'center',
    justifyContent: 'space-evenly',
  },
  socialIcon: {
    height: '25px',
    width: '25px',
    margin: '5px',
  },
  link: {
    cursor: 'pointer',
  },
  mapimage: {
    width: '160px',
    height: '160px',
    objectFit: 'cover'
  }
});
const mapStateToProps = (state: any) => ({
  order: state.orderStore?.order
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    getOrderDetailsById: OrderActions.getOrderDetailsById,
  }, dispatch);

const OrderSuccessComponent = (withStyles(styles as any)(OrderSuccess));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderSuccessComponent as any) as any);
