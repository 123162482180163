import { Theme, Typography } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { withStyles } from '@mui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from 'redux';
import EnhancedCategoryCard from "../../components/Cards/CategoryCard";
import ICategory from '../../models/category/ICategory';
import * as CategoryAction from '../../store/actions/Category.Actions';
const classNames = require('classnames');

export const bgColors = [
    { "id": 0, "bgColor": "#e6194B" },
    { "id": 1, "bgColor": "#f58231" },
    { "id": 2, "bgColor": "#ffe119" },
    { "id": 3, "bgColor": "#bfef45" },
    { "id": 4, "bgColor": "#3cb44b" },
    { "id": 5, "bgColor": "#42d4f4" },
    { "id": 6, "bgColor": "#4363d8" },
    { "id": 7, "bgColor": "#911eb4" },
    { "id": 8, "bgColor": "#a9a9a9" },
    { "id": 9, "bgColor": "#000075" }
];

interface IDashboardProps {
    classes?: any;
    menuCategories: any[];
    searchValue: string;
    isAppLoader: boolean,
    getCategoryListByRange: (start: number, limit: number, parameterName?: string, parameterValue?: string) => void;
}

class CategoryHome extends React.Component<RouteComponentProps<{}> & IDashboardProps> {
    public loader: boolean = false;
    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Menu',
        }
    }

    componentWillMount() {
        this.loader = true;
        this.props.getCategoryListByRange(0, 40);
    }

    componentDidMount() {
        this.loader = false;
    }

    public onClickMenu = (category: ICategory) => {
        this.props.history.push(`/category/menu/${category.categoryId}`);
    }

    public render(): JSX.Element {
        const { classes, menuCategories } = this.props;
        let counter: number = 0;

        return (
            <div className={classes.container}>
                <Typography component="div">
                    <Typography className={classes.title}>
                        All Categories
                    </Typography>
                    <ImageList cols={4} className={classes.cardContainer}>
                        {menuCategories?.map((menuCategory: ICategory, index: number) => (
                            <ImageListItem key={menuCategory.categoryId + 'unique' + index}
                                className={classNames(classes.gridCategoryTileStyle, classes.root)}>
                                <EnhancedCategoryCard category={menuCategory}
                                    bgColor={bgColors[counter++ % 10].bgColor}
                                    onClick={() => this.onClickMenu(menuCategory)} />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Typography>
                {(!this.loader && !this.props.isAppLoader && menuCategories?.length <= 0) &&
                    <ImageListItem >
                        <Typography align='center' component="span"
                            variant="body1"
                            color="textPrimary">No Category Found</Typography>
                    </ImageListItem>}

            </div>
        );
    }
}

const styles = (theme: Theme) => ({
    title: {
        fontSize: 34,
        padding: 5,
        [theme.breakpoints.down('xs')]: {
            fontSize: 29,
            paddingLeft: 10,
        },
    },
    container: {
        'height': 'auto',
        backgroundColor: "transparent",
        minWidth: '95%',
        '@media only screen and (min-width : 1224px)': {
            'padding-left': '10px',
            'padding-right': '10px',
            'padding-bottom': '20px',
        },
    },
    root: {
        'flex-shrink': 'unset',
        alignItems: 'center',
        cursor: 'pointer',
    },
    searchBar: {
        "margin": "10px",
        minWidth: '300px',
        height: '100%',
        borderRadius: '15px',
        flex: 1,
        backgroundColor: 'rgba(0,0,0,.1)',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        width: '80% !important'

    },
    iconButton: {
        padding: 10,

    },
    gridList: {
        flexWrap: 'nowrap',
        'overflowX': 'auto',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    gridCategoryTileStyle: {
        position: 'relative',
        float: 'left',
        width: '100%',
        minHeight: '120px',
        minWidth: '110px',
        overflow: 'hidden',
        height: '100% !important',
        '@media only screen and (min-width : 1224px)': {
            minHeight: '225px !important',
        }
    },
    gridDealTileStyle: {
        position: 'relative',
        float: 'left',
        width: '100%',
        minHeight: '160px',
        minWidth: '300px',
        overflow: 'hidden',
        height: '100% !important',
        '@media only screen and (min-width : 1224px)': {
            minWidth: '400px',
            minHeight: '220px !important',
        }
    },
    gridFeatureTileStyle: {
        position: 'relative',
        float: 'left',
        width: '100%',
        minHeight: '210px',
        minWidth: '180px',
        overflow: 'hidden',
        '@media only screen and (min-width : 1224px)': {
            minHeight: '310px !important',
            minWidth: '250px',
        }
    },
    cardContainer: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 10,
        },
    },
});

const mapStateToProps = (state: any) => ({
    menuCategories: state.category?.categoryList,
    isAppLoader: state.app?.isAppLoader
});


const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getCategoryListByRange: CategoryAction.fetchCategoryListByRange
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(CategoryHome as any)) as React.ComponentType<any>);