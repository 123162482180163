import { ActionType, IAppAction } from '../../actions/Helpers';
import IProduct from '../../../models/product/IProduct';
import ICategory from '../../../models/category/ICategory';

const initialState = {
    adminProductsList: [] as IProduct[],
    productsList: [] as IProduct[],
    selectedProductItem: {} as IProduct,
    editProductDetails: {} as IProduct,
    productListByCategoryId: [] as IProduct[],
    totalResults: Number(0),
    previousCount: Number(0),
    category: {} as ICategory,
}

export const productReducer = (state = initialState, action = {} as IAppAction) => {
    switch (action.type) {
        case ActionType.GET_PRODUCT_LIST:
            return { ...state, adminProductsList: action.payload }
        case ActionType.GET_PRODUCTS_LIST_SUCCESS:
            let updateGridArray: any[] = [];

            if (action.payload.data?.products) {
                if (state.productsList.length === 0) {
                    updateGridArray.push(...action.payload.data.products)
                } else if ((state.totalResults - state.productsList.length === action.payload.data.products.length)) {
                    if (action.payload.data.products.length !== 0) {
                        updateGridArray = [...state.productsList]
                    }
                    updateGridArray.push(...action.payload.data.products)
                } else if (action.payload.data.products.length === state.totalResults) {
                    updateGridArray.push(...action.payload.data.products)
                }
                else if ((state.previousCount === action.payload.data.products.length)) {
                    if (action.payload.data.products.length !== 0) {
                        updateGridArray = [...state.productsList]
                    }
                    updateGridArray.push(...action.payload.data.products)
                }
                else {
                    updateGridArray.push(...action.payload.data.products)
                }
            }
            return {
                ...state,
                productsList: updateGridArray,
                category: action.payload?.data?.category,
                previousCount: (action.payload.data && action.payload.data.products) ? action.payload.data.products.length : 0,
                totalResults: action.payload.data?.totalCount ? action.payload.data?.totalCount : 0
            };
        case ActionType.GET_PRODUCTS_LIST_FAILURE:
            return { ...state, productsList: [] }
        case ActionType.GET_PRODUCT_BY_ID_SUCCESS:
            return { ...state, selectedProductItem: action.payload }
        case ActionType.GET_PRODUCT_BY_ID_FAILURE:
            return { ...state, selectedProductItem: action.payload }
        case ActionType.REMOVE_SELECTED_PRODUCT_FORM_STORE:
            return { ...state, selectedProductItem: action.payload }
        case ActionType.EDIT_PRODUCT_DETAILS:
            return { ...state, editProductDetails: action.payload }
        case ActionType.GET_PRODUCT_BY_CATEGORY_ID_SUCCESS:
            return { ...state, productListByCategoryId: action.payload }
        case ActionType.GET_PRODUCT_BY_CATEGORY_ID_FAILURE:
            return { ...state, productListByCategoryId: [] }
        default:
            return state
    }
}
