import { Delete as DeleteIcon } from '@mui/icons-material';
import { Grid, Hidden, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Theme } from '@mui/material/styles';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { withStyles } from '@mui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import SimpleReactValidator from 'simple-react-validator';
import { DialogContent, DialogTitle } from '../../components/dialog/DialogTitle';
import IContact from '../../models/IContact';
import { formatMoney } from "../../pipes/PriceFormatter";
import { Authentication } from '../../state/Authentication';
import { openProductDetailPageModel } from "../../store/actions/App.Action";
import * as CartActions from '../../store/actions/Cart.Action';
import * as OrderActions from '../../store/actions/Order.Action';
import * as ProductActions from '../../store/actions/Product.Action';
import PaymentForm from './PaymentForm';
import * as UserActions from '../../store/actions/User.Actions';

const classNames = require('classnames');
declare global {
    interface Window {
        // add you custom properties and methods
        WePay: any;
    }
}

interface IPageProps {
    classes: any;
    orderPost: (order: any, cartId: string) => any;
    orderPut: (order: any) => any;
    contactPost: (contact: IContact) => IContact;
    isLoggedIn: boolean,
    isGuestLogin: boolean;
    locationId: string;
    authentication: Authentication;
    order: any;
    cart: any;
    user:any;
    makePayment: (orderId: any) => any;
    deleteCartItem: (cartItemId: number) => any;
    getRefreshCartDetails: () => void;
    getGitUserIsAvailable: (gitUserName: string) => any;
    putUpdateUserById: (userId: string, body: string) => any;

}

interface IPageState {
    firstName: string;
    lastName: string,
    phoneNumber: string;
    email: string;
    address1: string;
    address2: string;
    city: string;
    stateProvince: string;
    postalCode: string;
    state: string;
    notes: string;
    openPayMethodModel: boolean;
    cvv: string;
    cardNumber: string;
    cardHolderName: string;
    expDate: string;
    paymentURL: string;
    paymentDialogHeight: number;
    orderType: string;
    gitUserName:string;
    isValidGitName: boolean;
}

class ProceedToCheckout extends React.Component<RouteComponentProps & IPageProps, IPageState> {
    public validator: SimpleReactValidator = new SimpleReactValidator();
    public cardFormValidator: SimpleReactValidator = new SimpleReactValidator();

    constructor(props: any) {
        super(props);
        this.state = {
            firstName: this.props.authentication?.firstName ? this.props.authentication.firstName : '',
            lastName: this.props.authentication?.lastName ? this.props.authentication.lastName : '',
            phoneNumber: this.props.authentication?.phone ? this.props.authentication.phone : '',
            email: this.props.authentication?.email ? this.props.authentication.email : '',
            address1: '',
            address2: '',
            city: '',
            stateProvince: '',
            postalCode: '',
            state: '',
            notes: '',
            openPayMethodModel: false,
            cvv: '',
            cardNumber: '',
            cardHolderName: '',
            expDate: '',
            paymentURL: '',
            paymentDialogHeight: 513,
            orderType: '',
            gitUserName:this.props.user?.gitUserName,
            isValidGitName: true,

            }
    }

    private handleMessage = (event: any) => {
        if (!event.data || !event.data.id) {
            return;
        }

        switch (event.data.id) {
            case 'hpp-done':
                this.handleClosePayMethodModel();
                this.submitPayment('paid');
                break;
            case 'set-height':
                this.setState({ paymentDialogHeight: event.data.height });
                break;
            default:
                break;
        }
    }

    public onPayment = (orderId: any) => {
        this.props.makePayment(orderId).then((url: any) => {
            this.setState({ paymentURL: url });
        });
    }


    private onhandleChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.target.value } as IPageState)
    }

    private handleClickOpenPayMethodModel = () => {
        this.setState({ openPayMethodModel: true })
    };

    private handleClosePayMethodModel = () => {
        this.setState({ openPayMethodModel: false })
    };

    private submitPayment = async (orderStatus: string) => {
        const { firstName, lastName, phoneNumber, state, address2, email, address1, postalCode, city, notes,isValidGitName } = this.state;
        const { orderPost, getRefreshCartDetails } = this.props;
        const { cart } = this.props;
        const { shoppingCart, serviceCharge, taxTotal, total, subTotal, cartId } = cart || {};
        try {
            // cartId
            shoppingCart?.items?.forEach((eachItems: any) => delete eachItems.product.images);
            const makeOrder: any =
            {
                address1: address1,
                address2: address2,
                city: city,
                email: email,
                firstName: firstName,
                grandTotal: subTotal,
                itemDiscount: 0,
                items: [...shoppingCart?.items],
                lastName: lastName,
                mobileNo: phoneNumber,
                province: state,
                shipping: 0,
                zipCode: postalCode,
                status: orderStatus,
                notes: notes,
                subTotal: subTotal,
                tax: taxTotal,
                total: total,
                serviceCharge: serviceCharge
            }
            const userDetails: any = Object.assign({}, this.props.user);
            userDetails.gitUserName=this.state.gitUserName;
            orderPost(JSON.stringify(makeOrder), shoppingCart.cartId).then((response: any) => {
                getRefreshCartDetails();
                this.props.history.push(`/order-success/${response.data.orderId}`);
            })
            await this.props.putUpdateUserById(this.props.user. userId, JSON.stringify(userDetails)).then((response: any) => {        
            }).catch((error: any) => {
                console.error(error);
            });
        } catch (e) {
            console.error(e);
        }
    }

    public handleNext = (event: any) => {
        event.preventDefault(); this.handleClickOpenPayMethodModel();
      /*  if (this.validator.allValid()) {
            this.handleClickOpenPayMethodModel();
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }*/

    };

    public payAtStore = (event: any) => {
        event.preventDefault();
        if (this.validator.allValid() && this.state.isValidGitName) {
            this.submitPayment('unpaid');
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }

    };

    // private setOrderType = (orderType: string) => {
    //     this.setState({ orderType }, () => {
    //         LocalStorage.saveToLocalStorage(LocalStorage.storageKeys.orderType, orderType);
    //     });
    // }

    private backToProductEdit = (cartItem: any) => {
        this.props.history.push(`/product/${cartItem?.product.productId}`);
        // this.props.openProductDetailPageModel();
    }

    public componentWillMount = () => {
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.cardFormValidator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    public componentDidMount = async () => {
        const orderType = "";
        this.setState({ orderType: orderType ? orderType : 'Delivery' });
        window.addEventListener('message', this.handleMessage);
    }

    public componentWillUnmount() {
        window.removeEventListener('message', this.handleMessage);
    }

    private dummyPaymentMethod = () => {
        toast("Payment has paid successfully", { type: 'success' });
        this.props.history.push(`/order-success/${123}`);
    }

    private removeCartItem = async (item: any) => {
        await this.props.deleteCartItem(item.itemId).then((response: any) => {
            console.log('');
        }, (error: any) => {
            console.error(error);
        });
    }
    private validateGitUserName = () => {
        this.props.getGitUserIsAvailable(this.state.gitUserName || '').then((response) => {
            if (response && !response.data) {
                toast.error("Please enter valid git hub User Name")
                this.setState({ isValidGitName: false });
            } else {
                this.setState({ isValidGitName: true });
            }
        })
    }

    public render() {
        const { firstName, lastName, email, phoneNumber, address1, postalCode, city, state, notes, openPayMethodModel, orderType ,gitUserName} = this.state;
        const { classes, cart } = this.props;
        const { shoppingCart, serviceCharge, taxTotal, total, subTotal, cartCount } = cart;
        this.validator.purgeFields();
        this.cardFormValidator.purgeFields();
        const isOrderEmpty = !cart || !shoppingCart || shoppingCart?.items?.length === 0;
        return (
            <div className={classes.root}>
                <Grid container={true} spacing={3}>
                    <Grid xs={12} sm={7} item={true} className={classes.gridContent}>
                        <Grid container={true} spacing={4} >
                            <Container component="main" disableGutters={false} maxWidth="md">
                                <Typography variant="h6" gutterBottom={true}>Contact</Typography>
                                <Grid container={true} spacing={2} direction='row'>
                                    <Grid item={true} xs={12} sm={6}>
                                        <TextField
                                            className={classes.marginBottom5}
                                            required={true}
                                            id="firstName"
                                            name="firstName"
                                            label="First name"
                                            value={firstName}
                                            onChange={(e: any) => this.onhandleChange(e, 'firstName')}
                                            onBlur={() => this.validator.message('firsrt name', typeof firstName === "string" ? firstName.trim() : firstName,
                                                'required|alpha_num_space'
                                            )}
                                            fullWidth={true}
                                            variant="outlined"
                                            autoComplete="fname"
                                        />
                                        {this.validator.message('first name', firstName, 'required|alpha_num_space')}
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <TextField
                                            className={classes.marginBottom5}
                                            required={true}
                                            id="lastName"
                                            name="lastName"
                                            value={lastName}
                                            onChange={(e: any) => this.onhandleChange(e, 'lastName')}
                                            onBlur={() => this.validator.message('last name', typeof lastName === "string" ? lastName.trim() : lastName,
                                                'required|alpha_num_space'
                                            )}
                                            label="Last name"
                                            fullWidth={true}
                                            variant="outlined"
                                            autoComplete="lname"
                                        />
                                        {this.validator.message('last name', lastName, 'required|alpha_num_space')}
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <TextField
                                            className={classes.marginBottom5}
                                            required={true}
                                            id="email"
                                            name="email"
                                            value={email}
                                            onChange={(e: any) => this.onhandleChange(e, 'email')}
                                            onBlur={() => this.validator.showMessageFor('email')}
                                            label="Email"
                                            fullWidth={true}
                                            variant="outlined"
                                            autoComplete="email"
                                        />
                                        {this.validator.message('email', email, 'required|email')}
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <TextField
                                            className={classes.marginBottom5}
                                            required={true}
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            onChange={(e: any) => this.onhandleChange(e, 'phoneNumber')}
                                            onBlur={() => this.validator.showMessageFor('phone')}
                                            placeholder="e.g. 555 555 1212"
                                            label="Phone Number"
                                            fullWidth={true}
                                            variant="outlined"
                                            autoComplete="phoneNumber"
                                        />
                                        {this.validator.message('phone number', phoneNumber, 'required|phone')}
                                    </Grid>
                                    <Grid item={true} xs={12} sm={12}>
                                         <TextField
                                            className={classes.marginBottom5}
                                            required={true}
                                            id="gitUserName"
                                            name="gitUserName"
                                            value={gitUserName}
                                            onChange={(e: any) => this.onhandleChange(e, 'gitUserName')}
                                            onBlur={() =>{ this.validator.showMessageFor('gitUserName'); this.validateGitUserName() }}
                                            label="Git user name"
                                            fullWidth={true}
                                            variant="outlined"
                                            disabled={gitUserName!=null}
                                        />
                                        {this.validator.message('gitUserName', gitUserName, 'required')}
                                    </Grid>
                                    <Grid item={true} xs={12} sm={12}>
                                        <Typography variant="caption" component='div' gutterBottom={true}>
                                            By providing your phone number, you consent to receive text messages from Unwired related to your order. Standard message rates may apply. See our <a href='//#endregion' style={{ textDecoration: 'none' }}>Terms of Use</a> for more information.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                            {orderType === 'Delivery' && <>
                                <Container component="main" disableGutters={false} maxWidth="md">
                                    <Typography variant="subtitle2" style={{ paddingTop: '25px' }} gutterBottom={true}>Delivery Note</Typography>
                                    <Grid container={true} spacing={2} direction='row'>
                                        <Grid item={true} xs={12} sm={12}>
                                            <TextareaAutosize
                                                style={{ height: '74px' }}
                                                className={classes.textareaBoxSize}
                                                id="notes"
                                                name="notes"
                                                onChange={(e) => this.onhandleChange(e, 'notes')}
                                                placeholder="e.g. Please double bag. (optional)"
                                                value={notes}
                                                autoComplete="notes"
                                            />
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Container component="main" disableGutters={false} maxWidth="md">
                                    <Typography variant="h6" style={{ paddingTop: '25px' }} gutterBottom={true}>Address</Typography>
                                    <Grid container={true} spacing={2} direction='row'>
                                        <Grid item={true} xs={12} sm={12}>
                                            <TextField
                                                className={classes.marginBottom5}
                                                required={true}
                                                id="address1"
                                                name="address1"
                                                label="Address"
                                                onChange={(e) => this.onhandleChange(e, 'address1')}
                                                onBlur={() => this.validator.message('address', typeof address1 === "string" ? address1.trim() : address1,
                                                    'required|min:3|alpha_num_space'
                                                )}
                                                fullWidth={true}
                                                value={address1}
                                                variant="outlined"
                                                autoComplete="billing address-line1"
                                            />
                                            {this.validator.message('address', address1, 'required|min:3|alpha_num_space')}
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6}>
                                            <TextField
                                                className={classes.marginBottom5}
                                                required={true}
                                                id="city"
                                                value={city}
                                                onChange={(e) => this.onhandleChange(e, 'city')}
                                                onBlur={() => this.validator.message('city', typeof city === "string" ? city.trim() : city,
                                                    'required|min:3|alpha_num_space'
                                                )}
                                                name="city"
                                                label="City"
                                                fullWidth={true}
                                                variant="outlined"
                                                autoComplete="billing address-level2"
                                            />
                                            {this.validator.message('city', city, 'required|min:3|alpha_num_space')}
                                        </Grid>
                                        <Grid item={true} xs={12} sm={2}>
                                            <TextField id="state" name="state" required={true} value={state}
                                                className={classes.marginBottom5}
                                                onChange={(e) => this.onhandleChange(e, 'state')}
                                                onBlur={() => this.validator.message('state', typeof state === "string" ? state.trim() : state,
                                                    'required|min:2|alpha_num_space'
                                                )}
                                                variant="outlined"
                                                label="State"
                                                fullWidth={true}
                                            />

                                            {this.validator.message('state', state, 'required|min:2|alpha_num_space')}
                                        </Grid>
                                        <Grid item={true} xs={12} sm={4}>
                                            <TextField
                                                className={classes.marginBottom5}
                                                required={true}
                                                id="postalCode"
                                                value={postalCode}
                                                name="postalCode"
                                                onChange={(e) => this.onhandleChange(e, 'postalCode')}
                                                onBlur={() => this.validator.message('postal code', typeof postalCode === "number" ? postalCode : postalCode,
                                                    'required|min:5|max:5|integer'
                                                )}
                                                placeholder="e.g. 10002"
                                                label="Postal code"
                                                variant="outlined"
                                                fullWidth={true}
                                                autoComplete="billing postal-code"
                                            />
                                            {this.validator.message('postal code', postalCode, 'required|min:5|max:5|integer')}
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6} className={classes.marginBottom20PX}>
                                            <FormControlLabel
                                                control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
                                                label="Use this address for payment details"
                                            />
                                        </Grid>
                                    </Grid>
                                </Container>
                            </>}
                        </Grid>
                        <Grid item={true} xs={12} sm={12}>
                            <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', margin: '8px' }}>
                                <Button className={classes.paynowButton} variant="contained" style={{ minWidth: '50%', margin: '10px', borderRadius: '50px' }}
                                    onClick={(event) => this.handleNext(event)}>Pay Now</Button>
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} sm={12}>
                            <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', margin: '8px' }}>
                                <Button variant="contained" color="secondary" style={{ minWidth: '50%', margin: '10px', borderRadius: '50px' }} onClick={(event) => this.payAtStore(event)}>
                                    {orderType === 'Delivery' ? 'Pay Later' : 'Pay at Store'}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item={true} xs={12} sm={5} >
                        <Hidden only={['xs', 'sm']}>
                            <Container component="main" disableGutters={true} maxWidth="md" style={{ flexDirection: 'column' }}>
                                <Grid container={true} spacing={2} className={classes.rightPanel} >
                                    <Grid item={true} xs={12} sm={12} className={classes.panndingZero} >
                                        <div className={classes.cartHeader}>
                                            <Typography component='h6' className={classes.orderTitle}>Your order from</Typography>
                                            <Typography component='h6'>unwired</Typography>
                                        </div>
                                        <Divider />
                                        <Grid
                                            container={true}
                                            className={classNames(
                                                classes.productDetailsItem,
                                                isOrderEmpty ? classes.cartEmpty : ''
                                            )}
                                            style={{ textTransform: 'uppercase', }}
                                        >
                                            {cart?.shoppingCart?.items && cart.shoppingCart?.items?.map((cartItem: any) => {
                                                const { product, price, quantity, itemId } = cartItem
                                                return (
                                                    <Grid item={true} xs={12} sm={12} key={itemId} className={classes.panndingZero}>
                                                        <Grid container={true} className={classes.productDetails} style={{ textTransform: 'uppercase', justifyContent: 'row' }}>
                                                            <Grid item={true} xs={12} sm={2} >
                                                                <Typography>{quantity}</Typography>
                                                            </Grid>
                                                            <Grid item={true} xs={12} sm={6}>
                                                                <Button className={classes.itemButton}>
                                                                    <Typography onClick={() => this.backToProductEdit(cartItem)} >{product.name}</Typography>
                                                                </Button>
                                                            </Grid>
                                                            <Grid item={true} xs={12} sm={2}>
                                                                <Button className={classes.deleteButton}>
                                                                    <DeleteIcon style={{ fontSize: '24px', paddingTop: '2px' }} color='error' onClick={() => this.removeCartItem(cartItem)} />
                                                                </Button>
                                                            </Grid>
                                                            <Grid item={true} xs={12} sm={2} >
                                                                <Typography>$ {formatMoney(price)}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider />
                                                    </Grid>
                                                )
                                            })}
                                            {!isOrderEmpty &&
                                                <Grid item={true} xs={12} sm={12} className={classes.panndingZero}>
                                                    <Grid container={true} className={classes.productTotals}>
                                                        <Grid item={true} xs={12} sm={10}>
                                                            <Typography variant="body1" className={classes.marginBottom5}>Items subtotal:</Typography>
                                                        </Grid>
                                                        <Grid item={true} xs={12} sm={2} >
                                                            <Typography variant="body1" className={classes.marginBottom5}>$ {parseFloat(total).toFixed(2)}</Typography>
                                                        </Grid>
                                                        <Grid item={true} xs={12} sm={10}>
                                                            <Typography variant="body1" className={classes.marginBottom5}>Service fee:</Typography>
                                                        </Grid>
                                                        <Grid item={true} xs={12} sm={2} >
                                                            <Typography variant="body1" className={classes.marginBottom5}>$ {serviceCharge}</Typography>
                                                        </Grid>
                                                        <Grid item={true} xs={12} sm={10}>
                                                            <Typography variant="body1" className={classes.marginBottom5}>Tax:</Typography>
                                                        </Grid>
                                                        <Grid item={true} xs={12} sm={2}>
                                                            <Typography variant="body1" className={classes.marginBottom5}>$ {taxTotal}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                            {isOrderEmpty &&
                                                <Grid container={true} >
                                                    <Grid item={true} xs={12} sm={6} className={classes.panndingZero}>
                                                        <Typography variant="body2" align="center" gutterBottom={true} style={{ position: 'sticky', color: '#cacaca', transform: 'translateY(51px)' }} >Your bag is empty.</Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={12} className={classes.panndingZero}>
                                        <Grid container={true} className={classes.totalPriceContainer} style={{ textTransform: 'capitalize', backgroundColor: '#34aadc', color: 'white' }}>
                                            <Grid item={true} sm={6}>
                                                <Typography variant="h6">TOTAL:</Typography>
                                            </Grid>
                                            <Grid item={true} sm={6} >
                                                <Typography className={classes.orderTotal} variant="h6">$ {subTotal}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Hidden>
                    </Grid>
                </Grid>

                <Dialog
                    onClose={() => this.handleClosePayMethodModel()}
                    aria-labelledby="customized-dialog-title"
                    open={openPayMethodModel}
                    className={classes.paymentDialog}
                    maxWidth={false}
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={() => this.handleClosePayMethodModel()}
                        className={classes.dialogTitle}
                    />
      

                    <DialogContent className={classes.dialogContent} style={{ height: 713 }}>
                        <iframe id="payment-form" title="Payment form" className={classes.iframeRoot} 
                        src={'https://localhost:44327/Payment/15DB2065-20DE-4A4B-99A4-127C6715D832'} />
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

}


const styles = (theme: Theme) => ({
    iframeRoot: {
        backgroundColor: '#fff',
        border: 'none',
        height: '100%',
        "@media (max-width: 425px)": {
            'width': '333px'
        },
        "@media (max-width: 380px)": {
            'width': '333px'
        },
        "@media (min-width: 1200px)": {
            width: '675px'
        },
        "@media (min-width: 768px)": {
            width: '675px'
        }
    },
    dialogContent: {
        padding: 0,
        // width: 700,
    },
    paper: {
        width: '900px'
    },
    root: {
        margin: '32px',
        padding: '18px',
    },
    gridContent: {
        margin: 'auto',
        // padding: 10,
    },
    secondGridContainer: {
        border: '1px solid #ccc',
        left: 'auto',
        height: '91vh',
    },
    orderTypeButton: {
        width: 132,
        border: 'solid 1px #c0c0c0',
        margin: '5px 10px 5px 0',
        color: '#c0c0c0',
    },
    orderTypeButtonActive: {
        color: '#34aadc',
        borderColor: '#34aadc',
    },
    orderTypeButtonLabel: {
        display: 'block',
    },
    orderTypeIcon: {
    },
    productDetails: {
        width: '100%',
        padding: 5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    productDetailsItem: {
        // width: '70%',
    },
    productTotals: {
        marginTop: '20px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    paynowButton: {
        color: "black",
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.dark
        }
    },
    modifyProduct: {
        textDecoration: 'none',
        fontSize: '18px',
        paddingLeft: '8px',
        cursor: 'pointer',
        color: "white",
        backgroundColor: '#121212',
    },
    cartEmpty: {
        height: '50vh',
        background: "url('/static/images/shopping-cart.png') no-repeat center center",
        color: '#34aadc',
        backgroundSize: '',
        overflow: 'hidden'
    },
    cartIteamElement: {
        fontSize: '12px'
    },
    marginBottom20PX: {
        marginBottom: '20px',
    },
    totalPriceContainer: {
        padding: 5,
    },
    marginBottom5: {
        marginBottom: '5px'
    },

    cartHeader: {
        padding: '15px',
        minHeight: '52px',
    },
    orderTitle: {
        fontSize: '15px',
        fontWeight: 700,
        marginBottom: '5px',
    },
    textareaBoxSize: {
        minWidth: '100%',
        maxWidth: '100%',
        height: '74px',
        border: '2px solid #cccc'
    },
    orderTotal: {
        'text-align': 'right',
    },
    rightPanel: {
        boxShadow: '0 0 0 1px rgba(0,0,0,0.1) , 0 1px 4px 0 rgba(0,0,0,0.1)',
        top: '8%',
        padding: '8px',
        border: '1px solid #ccc',
        left: 'auto',
    },
    paymentDialog: {
        overflow: 'hidden',
        margin: 0,
    },
    dialogTitle: {
        padding: 0,
    },
   
    closeButton: {
        padding: 0,
    },
})
const mapStateToProps = (state: any) => ({
    utility: state.utility,
    authentication: state.authentication,
    order: state.order,
    cart: state.cart,
    isGuestLogin: state.authentication?.isGuestLogin,
    isLoggedIn: state.authentication?.isLoggedIn,
    locationId: state.authentication?.locationId,
    user: state.auth.user
})

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({
        editCartProduct: ProductActions.editProductDetails,
        deleteCartItem: CartActions.deleteCartItemById,
        orderPost: OrderActions.postMakeOrder,
        getRefreshCartDetails: CartActions.getShoppingCartDetails,
        getGitUserIsAvailable: UserActions.getGitUserIsAvailable,
        putUpdateUserById: UserActions.putUpdateUserById,
        openProductDetailPageModel,

    }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProceedToCheckout as any)) as React.ComponentType<any>);