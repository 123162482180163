import * as React from 'react';
import { CardActionArea, Card, Button, CardContent, Typography, CardMedia, Theme } from "@mui/material";

import ImgPlaceholder from '../Image/ImgPlaceholder';

import { withStyles } from '@mui/styles';
const classNames = require('classnames');

interface IEnhancedDealCardProps {
    classes?: any;
    product: any;
    onClick: () => void;
    index: number;
}

const ProductCard = (props: IEnhancedDealCardProps) => {

    const validateUrl = (value: string) => {
        const isValid = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
        if (isValid.test(value)) {
            return value;
        } else {
            return 'data:image/png;base64,' + value;
        }

    }
    const { classes, product, onClick, index } = props;
    const { description, images, name } = product;
    return (
       

        <CardActionArea className={classes.actionArea} onClick={() => onClick()} key={index}>
             <div className={classes.feature_containerFC} onClick={()=>onClick()}>
            <div className={classes.top_container}>
            <Card className={classes.cardStyle}>
                 <ImgPlaceholder
                            className={classes.media}
                            name={name}
                            imageUrl={images[0]?.image}
                            bgColor='gray'
                        />

                        
            </Card>
            </div>
            <div className={classes.dash_container}>
                    <div className={classes.half_circle_right} />
                    <div className={classes.horizontal_dash} />
                    <div className={classes.half_circle_left} />
                </div>
                 <div className={classes.bottom_container}>
                    <h4 className={classes.title} >
                        <Typography component="p"  className={classes.titleTitleText}>
                         {name}
                        </Typography>
                    </h4>
                    <h6 className={classes.description} >
                        <Typography  component="p" className={classes.titleDescriptionText}>
                            {description}
                        </Typography>
                    </h6>
                    <Button>
                        Order 
                    </Button>
                </div>
                </div>
        </CardActionArea>
    );
}


const styles = (theme: Theme) => ({
    priceWrap: {
        display: "flex",
        flexDirection: "row",
        margin: "5px"
    },
    orderButton: {
        width: '95%',
        fontSize: 20,
        borderRadius: '50px',
        bottom: '0px',
        margin: 'auto',
        display: 'block',
    },
    orderButtonLabel: {
        margin: '-10px',
        fontWeight: 400,
    },
    titleTitleText: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 10,
        [theme.breakpoints.down('md')]: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        [theme.breakpoints.down('sm')]: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 10,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    },
    titleDescriptionText: {
        fontSize: 10,
        fontWeight: 300,
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('md')]: {
            '-webkit-line-clamp': 3,
        },
    },
    "price": {
        "fontSize": "14px",
        "color": "gray",

    },
    "discountPrice": {
        "color": '#fa5176',
        "fontSize": "14px",
        "margin-left": "5px",
    },
    "bestseller": {
        "padding": "3px",
        "color": "black",
        "fontSize": "10px",
        "position": "absolute",
        "bottom": "0",
        "margin-left": "auto",
        "margin-right": "auto",
        "width": "90%"
    },
    "eta": {
        "padding": "3px",
        "color": theme.palette.primary.contrastText,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    media: {
        width: '100%',
        height: '100%',
        padding: 0,
        margin: 0,
    },
    "cardStyle": {
        boxShadow: 'none',
        "flexWrap": "nowrap",
        "width": "100%",
        "margin": "5px",
        "alignItems": "center",
    },
    title: {
        "fontSize": "10px",
        "color": "black",
        "margin": '0 5px',
        height: 28,
    },
    "restaurantWrap": {
        "display": "flex",
        "flexDirection": "row",
    },
    "restaurant": {
        "fontSize": "12px",
        "color": 'black',
        "margin": '5px'
    },
    description: {
        fontSize: "12px",
        color: 'gray',
        margin: '0 5px 5px',
        height: 82,
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('md')]: {
            marginBottom: 20,
        },
    },
    "status": {
        "color": "#3bd4e4",
        "fontSize": "14px",
        "margin": "1px"
    },
    feature_containerFC: {
        "borderRadius": "5%",
        "position": "relative",
        "background-color": "white",
        height: '300px',
        width: '100%',
    },
    top_container: {
        "display": "flex",
        "borderTopLeftRadius": "5%",
        "borderTopRightRadius": "5%",
        width: "100%",
        height: "45%",
    },
    bottom_container: {
        height: "auto",
        width: '100%',
        "borderBottomLeftRadius": "5%",
        "borderBottomRightRadius": "5%",

    },
    "half_circle_left": {
        "width": "5px",
        "height": "10px",
        "backgroundColor": "#f3f3f6",
        "borderBottomLeftRadius": "15px",
        "borderTopLeftRadius": "15px",
        "borderRight": "0",
        '@media only screen and (min-width : 1224px)': {
            "width": "7.5px",
            "height": "15px",

        }
    },
    "half_circle_right": {
        "width": "5px",
        "height": "10px",
        "backgroundColor": "#f3f3f6",
        "borderBottomRightRadius": "15px",
        "borderTopRightRadius": "15px",
        "borderLeft": "0",
        '@media only screen and (min-width : 1224px)': {
            "width": "7.5px",
            "height": "15px",

        }
    },
    horizontal_dash: {
        "border": "3px dashed #f3f3f6",
        "borderStyle": "none none dashed none",
        "width": "100%",
        "height": "4px",
        '@media only screen and (min-width : 1024px)': {
            height: '8px !important'
        }
    },
    dash_container: {
        "align": "center",
        "display": "flex",
        "width": "100%",
        "top": "45%",
        [theme.breakpoints.down('md')]: {
            'display': 'none',
        },
    },


    actionArea: {
        borderRadius: 16,
        transition: '0.2s',
        '&:hover': {
            transform: 'scale(1.1)',
        },
    },
    card: {
        minWidth: 256,
        borderRadius: 16,
        boxShadow: 'none',
        '&:hover': {
            boxShadow: `0 6px 12px 0 #203f52 -12 0.2  0.5`,
            border: '1px #5B9FED solid',
        },
    },
    content: {
        backgroundColor: "#203f52",
        padding: '1rem 1.5rem 1.5rem',
        '&:hover': {
            boxShadow: `0 6px 12px 0 #203f52 -12 0.2  0.5`,
            border: '1px #5B9FED solid',
        },
    },
    xtitle: {
        fontFamily: 'Keania One',
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem',
        },

        color: '#fff',
        textTransform: 'uppercase',
    },
    subtitle: {
        fontFamily: 'Montserrat',
        color: '#fff',
        opacity: 0.87,
        marginTop: '2rem',
        fontWeight: 300,
        fontSize: 10,
        [theme.breakpoints.between('xs', 'sm')]: {
            display: 'none',

        },

    },
    warpText: {
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textoverflow: 'ellipsis'
    },
    xmedia: {
        // objectFit: 'cover',
        // height: 600,
        // height: "10em",
        // paddingTop: '56.25%', // 16:9
    },
    cardContend: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden !important',
        textoverflow: 'ellipsis'
    }

});
export default withStyles(styles as any)(ProductCard as any) as any;

