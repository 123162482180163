import { Theme, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import * as React from 'react';
import ICategory from '../../models/category/ICategory';
import ImgPlaceholder from '../Image/ImgPlaceholder';
const styles = (theme: Theme) => ({
    categoryTitle: {
        padding: '5px 5px 5px 5px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('xs')]: {
            padding: '10px 5px 10px 5px',
            fontSize: '15px',
            textAlign: 'center',
            verticalAlign: 'bottom',
        },
    },
    category_outer_image: {
        border: '1px solid gray',
        backgroundColor: '#EBEBEB',
        [theme.breakpoints.up('sm')]: {
            width: 60,
            height: 60,
        },
        [theme.breakpoints.up('lg')]: {
            width: 95,
            height: 95,
        },
        [theme.breakpoints.down('xs')]: {
            width: 40,
            height: 40,
        },
        borderRadius: '100%',
    },

    category_inner_image: {
        width: 65,
        height: 65,
        border: '1px solid gray',
        [theme.breakpoints.up('sm')]: {
            width: 60,
            height: 60,
        },
        [theme.breakpoints.up('lg')]: {
            width: 83,
            height: 83,
        },
        [theme.breakpoints.down('xs')]: {
            width: 40,
            height: 40,
        },
        position: 'absolute'
    },
    category_image: {
        width: 100,
        height: 100,
        borderRadius: '50%',
        border: '1px solid gray',
        backgroundColor: '#EBEBEB',
        [theme.breakpoints.up('lg')]: {
            width: 150,
            height: 150,
        },
        [theme.breakpoints.down('xs')]: {
            width: 80,
            height: 80,
        },
        [theme.breakpoints.down('sm')]: {
            width: 100,
            height: 100,
        },
    },
    categoryWrap: {
        width: 300,
        height: 180,
        [theme.breakpoints.up('sm')]: {
            width: 300,
            height: 180,
        },
        [theme.breakpoints.up('lg')]: {
            width: 300,
            height: 180,
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 10,
            width: 110,
            height: 90,
        },
        borderRadius: '5%',
        verticalAlign: 'bottom',
        display: 'grid',
        alignItem: 'center',
        justifyContent: 'center'
    },

    bottom_content: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        textAlign: 'center',
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            justifyContent: 'center',
            width: 95,
            marginLeft: 5,
            marginRight: 5,
        },
    }
});

interface IEnhancedCategoryCardProps {
    onRequestSort?: (event: any, property: any) => any;
    onSelectAllClick?: any;
    classes?: any;
    selected?: number;
    count?: number;
    color?: string;
    category: ICategory;
    bgColor: string;
    onClick: () => void;
}

class CategoryCard extends React.Component<IEnhancedCategoryCardProps, {}> {
    public render() {
        const { classes, category, onClick, bgColor } = this.props;
        return (
            <Typography component={'div'} style={{ display: 'grid', justifyContent: 'center' }} onDoubleClick={() => onClick()}>
                <Typography component={'div'} 
                style={{
                    color: 'white',
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    top: '45px',
                }}>
                    <ImgPlaceholder
                        className={classes.category_image}
                        name={category.name}
                        imageUrl={category?.category_url}
                        bgColor={bgColor}
                    />
                </Typography>

                <Typography component={'div'} className={classes.categoryWrap} style={{ backgroundColor: bgColor }}>
                    <Typography component={'div'} className={classes.bottom_content}>
                        <Typography component={'p'} align='center' className={classes.categoryTitle}>
                            <b>{category.name}</b>
                        </Typography>
                    </Typography>
                </Typography>

            </Typography >
        );
    }
}

export default withStyles(styles as any)(CategoryCard as any) as any;

