import { IAppAction, ActionType } from './Helpers';
import { Spinner } from '../../state/Spinner';
import { AppState } from "../../state/AppState";
import { Dispatch } from "redux";
import { toast } from 'react-toastify';
import IProduct from '../../models/product/IProduct';
import * as ApiVariables from '../../service/ApiVariables'
import { fileUpload } from '../../service/ApiFileUploadUtils';

export const fetchProductsListRequest = () => {
    return { type: ActionType.GET_PRODUCTS_LIST_REQUEST, payload: {} };
}

export const fetchProductsSuccess = (response: any) => {
    return { type: ActionType.GET_PRODUCTS_LIST_SUCCESS, payload: response.data };
}
export const fetchProductsFailure = () => {
    return { type: ActionType.GET_PRODUCTS_LIST_FAILURE, payload: null };
}

export const fetchProductByIdRequest = (productId: string) => {
    return { type: ActionType.GET_PRODUCT_BY_ID_REQUEST, payload: productId };
};

export const fetchProductByIdSuccess = (response: any) => {
    return { type: ActionType.GET_PRODUCT_BY_ID_SUCCESS, payload: response };
};
export const fetchProductByIdFailure = () => {
    return { type: ActionType.GET_PRODUCT_BY_ID_FAILURE, payload: null };
};
export const fetchProductByIdStateReset = () => {
    return { type: ActionType.REMOVE_SELECTED_PRODUCT_FORM_STORE, payload: null };
};

export const fetchProductsListByCategoryIdRequest = (param: any) => {
    return { type: ActionType.GET_PRODUCT_BY_CATEGORY_ID_REQUEST, payload: param };
};
export const fetchProductsListByCategoryIdSuccess = (response: any) => {
    return { type: ActionType.GET_PRODUCT_BY_CATEGORY_ID_SUCCESS, payload: response };
};
export const fetchProductsListByCategoryIdFailure = (error: any) => {
    return { type: ActionType.GET_PRODUCT_BY_CATEGORY_ID_FAILURE, payload: error };
};

export const fetchProductsByRange = () => {

};

export const postSaveProduct = (body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
    return new Promise((resolve, reject) => {
        api({ ...ApiVariables.productApi.postSaveProduct, body }).then((response: any) => {
            toast(response.status.message, { type: 'success' })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            toast("Something went wrong", { type: 'error' })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(response);
        })
    })
}

export const postSaveProductImages = (productId: string, formData: FormData) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
    return new Promise((resolve, reject) => {
        ApiVariables.productApi.postSaveProductImages.productId = productId;
        fileUpload({ ...ApiVariables.productApi.postSaveProductImages, formData }).then((response: any) => {
            toast(response.status.message, { type: 'success' })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            toast("Something went wrong", { type: 'error' })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(response);
        })
    })
}
export const putUpdateProduct = (productId: string, body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
    return new Promise((resolve, reject) => {
        ApiVariables.productApi.putUpdateProductById.productId = productId
        api({ ...ApiVariables.productApi.putUpdateProductById, body }).then((response: any) => {
            toast(response.status.message, { type: 'success' })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            toast("Something went wrong", { type: 'error' })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(response);
        })
    })
}


export const getProductById = (productId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
    return new Promise((resolve, reject) => {
        ApiVariables.productApi.getProductById.productId = productId;
        api(ApiVariables.productApi.getProductById).then((response: any) => {
            dispatch({ type: ActionType.EDIT_PRODUCT_DETAILS, payload: response.data });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};
export const openProductDetailPageModel = (): IAppAction => {
    return {
        type: ActionType.OPEN_PRODUCT_DETAIL_PAGE_MODEL
    };
};

export const closeProductDetailPageModel = (): IAppAction => {
    return {
        type: ActionType.CLOSE_PRODUCT_DETAIL_PAGE_MODEL
    };
};

export const resetEditProductDetails = (): IAppAction => {
    return { type: ActionType.EDIT_PRODUCT_DETAILS, payload: {} };
};
export const editProductDetails = (productDetails: IProduct): IAppAction => {
    return { type: ActionType.EDIT_PRODUCT_DETAILS, payload: productDetails };
};

export const storeSelectedProductDetails = (product: any): IAppAction => {
    return { type: ActionType.SELECTED_PRODUCT, payload: product };
};
export const deleteSelectedProductFromStore = (): IAppAction => {
    return { type: ActionType.REMOVE_SELECTED_PRODUCT_FORM_STORE, payload: {} };
};

export const getProductsByRange = (start: number, limit: number, parameterName?: any, parameterValue?: any, userId?: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
    return new Promise((resolve, reject) => {
        ApiVariables.productApi.getProductsByRange.setStart = start;
        ApiVariables.productApi.getProductsByRange.setLimit = limit;
        ApiVariables.productApi.getProductsByRange.setParameterName = parameterName ? parameterName : null;
        ApiVariables.productApi.getProductsByRange.setParameterValue = parameterValue ? parameterValue : null;
        ApiVariables.productApi.getProductsByRange.setUserId = userId ? userId : null;
        api(ApiVariables.productApi.getProductsByRange).then((response: any) => {
            dispatch({ type: ActionType.GET_PRODUCT_LIST, payload: response.data });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};

export const getCategoryByIdProductRange = (categoryId: any, start: number, limit: number, parameterName?: any, parameterValue?: any, userId?: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
    return new Promise((resolve, reject) => {
        ApiVariables.productApi.getCategoryByIdProductRange.setStart = start;
        ApiVariables.productApi.getCategoryByIdProductRange.setLimit = limit;
        ApiVariables.productApi.getCategoryByIdProductRange.setParameterName = parameterName ? parameterName : null;
        ApiVariables.productApi.getCategoryByIdProductRange.setParameterValue = parameterValue ? parameterValue : null;
        ApiVariables.productApi.getCategoryByIdProductRange.setCategoryId = categoryId;
        api(ApiVariables.productApi.getCategoryByIdProductRange).then((response: any) => {
            dispatch({ type: ActionType.GET_PRODUCTS_LIST_SUCCESS, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};

export const resetCategoryByIdProducts = (): IAppAction => {
    return { type: ActionType.GET_PRODUCTS_LIST_SUCCESS, payload: [] };
};