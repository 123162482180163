import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from '../@history';
import store from './store';
import AppContext from './AppContext';
import routes from './configs/routes.config';
import Layout from './layout/Layout';
import { Auth0Provider, withAuth0 } from '@auth0/auth0-react';
import './App.css';

function App() {
  return (
    <React.StrictMode>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN ? process.env.REACT_APP_AUTH0_DOMAIN : ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ? process.env.REACT_APP_AUTH0_CLIENT_ID : ''}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        redirectUri={window.location.origin}
      // useRefreshTokens={true}
      // cacheLocation={"localstorage"}
      >
        <AppContext.Provider value={{ routes }}>
          <Provider store={store}>
            <Router history={history}>
              <Layout history={history}/>
            </Router>
          </Provider>
        </AppContext.Provider>

      </Auth0Provider>
    </React.StrictMode>
  );
}

export default withAuth0(App);
