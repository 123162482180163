import ICategory from '../../../models/category/ICategory';
import { ActionType,IAppAction } from '../../actions/Helpers';
const featuredCategoryName1row = '';
const featuredCategoryName2row = '';


const initialState = {
    adminCategoryList:[] as ICategory[],
    categoryList: [] as ICategory[],
    featuredCategoryName1row: '',
    featuredCategoryName2row: '',
    featuredCategory1row: {} as ICategory,
    featuredCategory2row: {} as ICategory
}
export const categoryReducer = (state = initialState, action = {} as IAppAction) => {
    switch (action.type) {
        case ActionType.GET_CATEGORY_LIST_DASHBOARD_SUCCESS:
            const menu = action.payload as ICategory[];
            const onlineMenu = {
                ...menu,
                menuCategories: menu?.sort((a: any, b: any) => {
                    if (a.sortOrder < b.sortOrder) { return -1; }
                    if (a.sortOrder > b.sortOrder) { return 1; }
                    return 0;
                }),

            };
            const featuredCategory1row = onlineMenu?.menuCategories?.length > 0 ? onlineMenu.menuCategories[0] : null;
            const featuredCategory2row = onlineMenu?.menuCategories?.length > 1 ? onlineMenu.menuCategories[1] : null;

            if (featuredCategory1row) {
                featuredCategory1row.items?.sort((a: any, b: any) => {
                    if (a.priority < b.priority) { return -1; }
                    if (a.priority > b.priority) { return 1; }
                    return 0;
                })
            }
            if (featuredCategory2row) {
                featuredCategory2row.items?.sort((a: any, b: any) => {
                    if (a.priority < b.priority) { return -1; }
                    if (a.priority > b.priority) { return 1; }
                    return 0;
                })
            }

            return {
                ...state,
                featuredCategory1row,
                featuredCategory2row,
                featuredCategoryName2row: !featuredCategory1row ? featuredCategoryName1row : featuredCategory1row.name,
                featuredCategoryName3row: !featuredCategory2row ? featuredCategoryName2row : featuredCategory2row.name,
            }
        case ActionType.GET_CATEGORY_LIST_DASHBOARD_FAILURE:
            return {
                ...state,
                featuredCategory1row: null,
                featuredCategory2row: null
            }
            case ActionType.GET_CATEGORY_LIST_SUCCESS:
                return {
                    ...state,
                    categoryList: action.payload
                }
            case ActionType.SET_CATEGORY_SELECT_BOX_VALUE:
                return {
                    ...state,
                    adminCategoryList: action.payload
                }

        default:
            return state
    }
}
