import { createStyles, Theme } from '@mui/material';
import CardMedia from "@mui/material/CardMedia";
import { WithStyles, withStyles } from '@mui/styles';
import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
const styles = (theme: Theme) => createStyles({
    acronym: {
        textTransform: 'uppercase',
        display: 'inline-block',
        textAlign: 'center',
        fontSize: '30px',
        fontWeight: 'bold',
        color: '#FFF',
    },
    acronymOverlay: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#000',
        opacity: 0.1,
    }
});

interface IPageProps {
    name: string | undefined;
    imageUrl: string | undefined;
    bgColor: string;
    className?: string;
}

class ImgPlaceholder extends React.Component<RouteComponentProps<{}> & WithStyles<typeof styles> & IPageProps> {

    public render() {
        const { classes, name, imageUrl, bgColor, className } = this.props;
        return (
            <CardMedia
                className={className}
                image={imageUrl}
                title={name}
            />

        );
    }

    private getAcronym = (name: string | undefined): string => {
        if (!name) {
            return '';
        }

        const matches: any = name.match(/\b(\w)/g);
        return matches?.join('');
    }
}

export default withRouter(withStyles(styles as any)(ImgPlaceholder));
