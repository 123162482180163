import React from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import './ProductDetailView.css'
import { formatMoney } from '../../pipes/PriceFormatter'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from "react-router-dom";
import Grid from '@mui/material/Grid';
import ProductSlider from '../../components/Slider/ProductSlider';
import { stripHtml } from '../../util/HelperFunctions';
import IProduct from '../../models/product/IProduct';
import { Utility } from '../../state/Utility';
import { withAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@mui/styles';
import * as ProductAction from '../../store/actions/Product.Action'
import { cartPopWindowOpen, cartPopWindowClose } from '../../store/actions/App.Action'
import * as CartAction from '../../store/actions/Cart.Action'
interface IPageProps {
    classes: any;
    utility?: Utility;
    selectedProductItem: IProduct;
    shoppingCart?: any;
    history: any;
    createCart: (addItemToCart: string) => any;
    updateCartItem: (cartId: string, orderItem: any) => any;
    orderRemoveItem: (orderItemId: number) => Promise<any>;
    user: any;
    getProductDetailsById: (productId?: string) => any;
    getShoppingCartDetails: () => any;
    resetEditProductDetails?: () => void;
    fetchProductByIdStateReset: () => void;
    auth0: any;
    cartPopWindowOpen: () => void;
    cartPopWindowClose: () => void;
}

interface IPageState {
    itemQuantity: number;
    selectedProductItem: IProduct;
}

interface IRouteParams { id: string, param2?: string }
class ProductDetailView extends React.Component<RouteComponentProps<IRouteParams> & IPageProps, IPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            itemQuantity: 1,
            selectedProductItem: {},
        }
    }

    public componentWillMount() {
        let productId = this.props.match.params.id as any;
        if (!productId) {
            const cartItem: any = localStorage.getItem('selectedProductItem');
            productId = cartItem?.product?.productId;
        }
        this.props.getProductDetailsById(productId);
    }

    public componentWillUnmount() {
        this.props.fetchProductByIdStateReset();
    }

    private quantityUp = () => {
        const itemQuantity = this.state.itemQuantity + 1;
        this.setState({ itemQuantity });
    }

    private quantityDown = () => {
        const itemQuantity = this.state.itemQuantity > 1 ? this.state.itemQuantity - 1 : 1;
        this.setState({ itemQuantity });
    }

    private addToCart = async () => {
        const { selectedProductItem } = this.props;
        const { itemQuantity } = this.state;
        const payload = {
            items: [
                {
                    "product": {
                        productId: selectedProductItem.productId
                    },
                    "price": selectedProductItem.price,
                    "quantity": itemQuantity,
                }
            ],
            "user": {
                "userId": localStorage.getItem('userId'),
            }
        }

        if (this.props.shoppingCart && this.props.shoppingCart?.cartId) {
            await this.props.updateCartItem(this.props.shoppingCart.cartId, JSON.stringify(payload)).then((response: any) => {
                if (response.data) {
                    this.makeCallShoppingCartDetails(true);
                }

            });
        } else {
            await this.props.createCart(JSON.stringify(payload)).then(async (response: any) => {
                if (response.data) {
                    this.makeCallShoppingCartDetails(true);
                }
            });
        }

    };

    private makeCallShoppingCartDetails = async (isShowup: boolean) => {
        await this.props.getShoppingCartDetails().then(async (response: any) => {
            if (response && response.status.success === "SUCCESS") {
                if (isShowup) {
                    this.props.cartPopWindowOpen();
                }
            }
        });
    }

    private backToShop = () => {
        this.props.history?.goBack();
    }
    public componentDidUpdate(prevProps: IPageProps, prevState: IPageState) {
        if (prevProps?.selectedProductItem?.productId !== prevState?.selectedProductItem?.productId) {
            const { selectedProductItem } = prevProps || {};
            if (selectedProductItem) {
                this.setState({ selectedProductItem });
            }
        }
    }
    public componentDidMount() {
        const selectedProductItem = this.props?.selectedProductItem;
        this.setState({ selectedProductItem })
        let itemQuantity = 1;
        if (selectedProductItem && selectedProductItem.quantity) {
            itemQuantity = selectedProductItem.quantity;
        }
        this.setState({
            itemQuantity,
        });
    }

    public render() {
        const { classes, selectedProductItem } = this.props;
        const { itemQuantity } = this.state;
        const isEdit = false;

        return (
            <div className={classes.rootX}>
                <Grid container={true} spacing={3} >
                    <Grid item={true} xs={12} sm={6} >
                        <div className={`${classes.safaryStickyDescription} ${classes.stickyDescription}`}>
                            <ListItem alignItems="flex-start" className={'MuiListItem-root'}>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography
                                                className={classes.name}
                                                color="textPrimary"
                                            >{selectedProductItem?.name && stripHtml(selectedProductItem.name)}</Typography>
                                            <Typography align="center" className={classes.description}>{selectedProductItem?.description && stripHtml(selectedProductItem?.description)}</Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            {selectedProductItem?.images && <ProductSlider images={selectedProductItem.images} />}
                        </div>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <div style={{ padding: '10px' }}>
                            <div>
                                <List className={classes.root}>
                                    <ListItem className={classes.subheaderList}><Typography align="center" className={classes.subheader}> Choose Amount</Typography></ListItem>
                                    <ListItem alignItems="flex-start" className={'MuiListItem-root'}>
                                        <div className={classes.quantityControl}>
                                            <Button onClick={() => this.quantityDown()}>
                                                <RemoveCircleOutlineOutlinedIcon
                                                    fontSize="small" className={classes.inputMinus}
                                                />
                                            </Button>
                                            <input
                                                type="number" step={1} max={20} min={1} value={itemQuantity ? itemQuantity : 1} title="Qty"
                                                className={classes.qty}
                                                readOnly={true} />
                                            <Button onClick={() => this.quantityUp()}>
                                                <AddCircleOutlineOutlinedIcon
                                                    fontSize="small" className={classes.inputPlus}
                                                />
                                            </Button>
                                        </div>
                                    </ListItem>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <div className={classes.totalPrice}>
                                                    <Typography
                                                        component="span"
                                                        className={classes.price}
                                                        color="textPrimary"
                                                    >Total: </Typography>
                                                    <Typography
                                                        component="span"
                                                        className={classes.price}
                                                        color="textPrimary"
                                                    >{selectedProductItem?.price && '$' + formatMoney((selectedProductItem?.price) * (itemQuantity ? itemQuantity : 1))}</Typography>
                                                </div>
                                            </React.Fragment>
                                        }
                                    />
                                    <hr />
                                    <div className={classes.shoppingButtons}>
                                        <ListItem className={classes.buttonsList}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ width: '100%', margin: '10px', borderRadius: '50px' }}
                                                onClick={() => this.addToCart()}
                                            >
                                                {isEdit ? 'Save Changes' : 'Add to Order'}
                                            </Button>
                                        </ListItem>
                                        <ListItem className={classes.buttonsList}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                style={{ width: '100%', margin: '10px', borderRadius: '50px' }}
                                                onClick={() => this.backToShop()}
                                            >
                                                BACK TO SHOPPING
                                            </Button>
                                        </ListItem>
                                    </div>
                                </List>
                            </div >
                        </div>
                    </Grid>

                </Grid>
            </div>
        );
    };
};
const useStyles = (theme: Theme) => ({
    root: {
        width: '100%',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up('xs')]: {
        },
        [theme.breakpoints.down('sm')]: {
            position: 'initial',
            paddingBottom: 100,
        },
    },
    shoppingButtons: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#fff',
        },
    },
    buttonsList: {
        maxWidth: 500,
        margin: 'auto',
        padding: 0,
    },
    subheader: {
        padding: '10px',
    },
    subheaderList: {
        backgroundColor: theme.palette.primary.main,
        justifyContent: 'center',
    },
    modifierContainer: {
        '& > *': {
            margin: theme.spacing(1),
        },
        "white-space": "nowrap"
    },
    modifierOptions: {
        "display": "flex",
        "justify-content": "space-between",
        backgroundColor: theme.palette.background.paper,
        "margin": "3px auto",
    },
    '.MuiListItem-root': {
        'padding-top': '0px',
        'padding-bottom': '9px'
    },
    grandChildOptionButton: {
        backgroundColor: '#f3f3f6',
        '&:hover': {
            backgroundColor: '#0070d26b',
        },
    },
    grandChildOptionButtonActive: {
        backgroundColor: '#0070D2',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0070D2',
        }
    },
    name: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '30px',
        fontWeight: 500,
        textTransform: 'uppercase',
    },
    description: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '25px',
        fontWeight: 300,
        color: '#8D92A3',
    },
    price: {
        display: 'flex',
        justifyContent: 'right',
        fontSize: '25px',
        fontWeight: 500,
    },
    alreadyIncart: {
        fontSize: 12,
        color: 'green'
    },
    warning: {
        fontSize: 12,
        color: '#f1a907'
    },
    formControl: {
        margin: '2px',
        '@media only screen and (min-width : 1224px)': {
            'max-width': '299px',
            'min-width': '285px !important',
        },

        '@media only screen and (min-device-width : 320px) and (max-device-width : 480px)': {
            'max-width': '299px',
            'min-width': '209px !important',
        }
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select: {
        // 'padding-right': '174px',
    },
    totalPrice: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        padding: '20px',
    },
    quantityControl: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: 5,
        color: '#8D92A3',
    },
    inputMinus: {
        marginRight: 5,
        color: '#8D92A3',
        [theme.breakpoints.down('xs')]: {
            margin: 0,
        },
    },
    inputPlus: {
        marginLeft: -5,
        color: '#8D92A3',
        [theme.breakpoints.down('xs')]: {
            margin: 0,
        },
    },
    qty: {
        border: '0',
        height: '30px',
        textAlign: 'center',
        fontWeight: 400,
        fontSize: '15px',
        borderRadius: 0,
        maxWidth: 40,
        '&:focus': {
            outline: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 40,
            textAlign: 'center',
        },
    },
    errorMessage: {
        color: '#ff0000',
        textAlign: 'center',
        fontSize: '22px',
    },
    safaryStickyDescription: {
        position: '-webkit-sticky',
    },
    stickyDescription: {
        position: 'sticky',
        top: 0,
    },
    rootX: {
        marginBottom: "100px",
    }
});
const mapStateToProps = (state: any) => {
    return {
        selectedProductItem: state.product?.selectedProductItem,
        shoppingCart: state.cart?.shoppingCart
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getProductDetailsById: ProductAction.fetchProductByIdRequest,
        fetchProductByIdStateReset: ProductAction.fetchProductByIdStateReset,
        getShoppingCartDetails: CartAction.getShoppingCartDetails,
        createCart: CartAction.postCreateCart,
        updateCartItem: CartAction.putUpdateCart,
        cartPopWindowOpen,
        cartPopWindowClose,
    }, dispatch);
};
export default withRouter(withAuth0(connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles as any)(ProductDetailView as any))) as React.ComponentType<any>);
