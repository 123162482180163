import * as React from 'react';
import { Theme, Typography, Grid, ImageListItem as GridListTile } from '@mui/material';
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from "react-router-dom";
import ProductCard from '../../components/Cards/ProductCard';
import { withStyles } from '@mui/styles';
import { fetchProductsListRequest } from '../../store/actions/Product.Action'
import IProduct from '../../models/product/IProduct';

interface IProps {
    classes?: any;
    productsList: any[];
    width: any;
    fetchProductsListRequest: () => void;
}

class ProductListView extends React.Component<RouteComponentProps<{}> & IProps> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Menu',
        }
    }

    componentDidMount() {
        this.props.fetchProductsListRequest();
    }

    public onClickMenu = (product: IProduct) => {
        this.props.history.push(`/product/${product.productId}`);
    }

    public render(): JSX.Element {
        const { classes, productsList } = this.props;
        return (
            <div className={classes.container}>
                {(Array.isArray(productsList) && productsList.length > 0) ?
                    <Typography component="div" style={{ overflowX: 'hidden', }}>
                        <Typography component={'div'} style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                            <Typography component='p' className={classes.title}  >
                                All Products
                            </Typography>
                        </Typography>
                        <Typography component="div" className={classes.productContainer}>
                            <Grid container={true} spacing={10}>
                                {productsList?.map((item: any, index: number) => (
                                    <Grid item={true} key={item.id} 
                                    xs={12} sm={6} md={3} lg={3} xl={3} 
                                    style={{ alignItems: 'center', marginBottom: 25 }}>
                                        <ProductCard
                                            index={index}
                                            product={item}
                                            onClick={() => this.onClickMenu(item)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Typography>
                    </Typography>
                    : <GridListTile >
                        <Typography component={'div'} style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                            <Typography align='center' component="p"
                                variant="body1"
                                color="textPrimary">No Product Found</Typography>

                        </Typography>
                    </GridListTile>}
            </div>
        );
    }
}

const styles = (theme: Theme) => ({
    tile: {
        borderRadius: 16
    },
    title: {
        position: 'static',
        top: 0,
        fontSize: 34,
        padding: 5,
        marginBottom: 30,
        [theme.breakpoints.down('xs')]: {
            fontSize: 29,
            paddingLeft: 10,
        },

    },
    container: {
        'height': 'auto',
        backgroundColor: "white",
        minWidth: '95%',
        marginBottom: 30,

    },
    root: {
        'flex-shrink': 'unset',
        alignItems: 'center',
        cursor: 'pointer',
    },
    iconButton: {
        padding: 10,

    },

    productContainer: {
        position: 'relative',
        paddingLeft: 15,
        paddingRight: 15,
        [theme.breakpoints.between('xs', 'sm')]: {
            paddingLeft: 30,
            paddingRight: 30,
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 20,
            paddingRight: 20,
        },
    }



});
const mapStateToProps = (state: any) => ({
    productsList: state.product?.productsList,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        fetchProductsListRequest: fetchProductsListRequest
    }, dispatch);
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(ProductListView as any)) as React.ComponentType<any>);